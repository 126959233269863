import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {FormViewTypes} from '../../../_shared/form-view-types.enum';
import {FormViewService} from '../../../_shared/services/form-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityForm} from '../../_controllers/entity-form';
import {SiteService} from '../services/site.service';


@Component({
	selector: 'app-site-details',
	templateUrl: './site-details.component.html',
	styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent extends EntityForm {
	public formViewType: FormViewTypes = FormViewTypes.SiteDetails;

	constructor(
		protected formViewService: FormViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		private siteSvc: SiteService,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntity(guid: string): Promise<CrmEntity> {
		return this.siteSvc.getSite(guid);
	}


}
