import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../../authentication.service';

export interface ReportType {
	entityName: string,
	displayName: string;
}

export interface NewReport {
	date: string,
	time: string,
	type: string,
	documents: File[]
}

export enum ReportTypes {
	Draft = 157410000,
}

export const ReportTypeLabels = {
	[ReportTypes.Draft]: 'Draft',
};

@Injectable()
export class ReportService {
	api: string = '/api';

	constructor(
		private http: HttpClient,
		private httpClient: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	public getTypes(): Observable<ReportType[]> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization(),
		});

		return this.httpClient.get<ReportType[]>(`${this.api}/paperwork/types`, {headers});
	}

	public create(visitId: string, report: NewReport, files: File[]): Promise<void> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		const data = new FormData();
		const date = moment(report.date).startOf('day');

		const hours = report.time.split(':')[0];
		const mins = report.time.split(':')[1];


		const dateTime = date.clone().hours(parseInt(hours)).minutes(parseInt(mins));

		data.set('dateTime', dateTime.toISOString());
		data.set('type', report.type);

		for (const file of files) {
			data.append('file', file, file.name);
		}

		return this.http.post<void>(`${this.api}/visits/${visitId}/paperwork`, data, {headers})
			.toPromise();
	}

}
