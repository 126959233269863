import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../authentication.service';
import {ContractService} from '../../contract.service';
import {Contract} from '../../interfaces/contract';
import {User} from '../../interfaces/user';

@Component({
	selector: 'app-navbar-wrapper',
	templateUrl: './navbar-wrapper.component.html',
	styleUrls: ['./navbar-wrapper.component.scss'],
})
export class NavbarWrapperComponent implements OnInit {
	public selectedContract!: Contract;
	public welcomeMsg!: string;
	public user!: User;
	public $contracts = this.contractService.getContracts();
	public showLogin: boolean = false;

	constructor(
		private router: Router,
		public auth: AuthenticationService,
		public contractService: ContractService,
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.auth.currentUser
			.subscribe(user => {
				this.user = user;

				if (user) {
					const [forename] = user.name.split(' ');
					this.welcomeMsg = forename;
				} else {
					this.welcomeMsg = null!;
				}
			});

		this.auth.currentContract.subscribe(contract => this.selectedContract = contract);
	}

	public async submitLogout(): Promise<void> {
		localStorage.clear();
		try {
			await navigator.credentials.preventSilentAccess();
		}
		catch (e) {
			console.log(e);
		}
		await this.router.navigate(['login']);
	}

	public compareContracts(c1: Contract, c2: Contract): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	public showPassword() {
this.showLogin=true;
	}
}
