<div id="loader" [class.visible]="loading">
<!--<div id="loader" class="visible">-->
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>

  <div class="loader-text">
    {{ text }}
  </div>
</div>