import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {SiteService} from '../services/site.service';

@Component({
	selector: 'app-site-visits',
	templateUrl: './site-visits.component.html',
	styleUrls: ['./site-visits.component.scss'],
})
export class SiteVisitsComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.SiteVisits;

	public constructor(
		protected entityService: SiteService,
		protected entitySvc: SiteService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.entitySvc.getSiteVisits(this.id, params);
	}
}
