import {Directive, ElementRef} from '@angular/core';
// import * as dialogPolyfill from 'dialog-polyfill';

@Directive({
	selector: 'dialog',
})
export class DialogDirective {
	constructor(el: ElementRef) {
		// dialogPolyfill.registerDialog(el.nativeElement);
	}
}
