<ri-loader></ri-loader>

<app-page-toolbar>
    <app-toolbar-filter [selectedView]="selectedView" (selectedViewChanged)="changeView($event)"
                        [filter]="filter"></app-toolbar-filter>

    <app-toolbar-search (searchTerm)="search($event)" />

    <app-toolbar-spacer></app-toolbar-spacer>

    <app-toolbar-csv-download (click)="downloadCSV()">Download Workbook
    </app-toolbar-csv-download>
    <app-toolbar-csv-download
            (click)="downloadBatch()"
            [disabled]="listLayout.selectionModel.isEmpty()">
        Download Selected
    </app-toolbar-csv-download>
    <app-toolbar-icon (click)="handleRefreshClick()" src="assets/icons/refresh.svg"></app-toolbar-icon>
</app-page-toolbar>

<app-xml-list-layout
        showCheckbox="true"
        [(listView)]="selectedView"
        [entityList]="entities"
        (listViewChange)="handleViewChange()"
></app-xml-list-layout>

<app-page-footer>
  <app-powered-by/>
  <app-pagination [currentPage]="currentPage" [maxRecords]="maxRecords" [records]="entities"></app-pagination>
</app-page-footer>
