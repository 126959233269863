import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FilterDialogModule} from './filter-dialog/filter-dialog.module';
import {NewJobDialogModule} from './new-job-dialog/new-job-dialog.module';
import {NewNoteDialogModule} from './new-note-dialog/new-note-dialog.module';
import {NewReportDialogModule} from './new-report-dialog/new-report-dialog.module';
import {PageToolbarModule} from './page-toolbar/page-toolbar.module';
import {PaginationModule} from './pagination/pagination.module';
import {PipesModule} from './pipes/pipes.module';
import {FormViewService} from './services/form-view.service';
import {ListViewService} from './services/list-view.service';
import {MetadataService} from './services/metadata.service';
import {XmlListLayoutModule} from './xml-list-layout/xml-list-layout.module';
import {XmlPageLayoutModule} from './xml-page-layout/xml-page-layout.module';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PoweredByComponent } from './page-footer/powered-by/powered-by.component';

@NgModule({
	imports: [
		CommonModule,
		XmlPageLayoutModule,
		XmlListLayoutModule,
		PageToolbarModule,
		FilterDialogModule,
		NewNoteDialogModule,
		NewJobDialogModule,
		NewReportDialogModule,
		PaginationModule,
		PipesModule,
	],
  exports: [
    XmlListLayoutModule,
    XmlPageLayoutModule,
    PageToolbarModule,
    FilterDialogModule,
    PaginationModule,
    NewNoteDialogModule,
    NewJobDialogModule,
    NewReportDialogModule,
    PipesModule,
    PageFooterComponent,
    PoweredByComponent,
  ],
	providers: [
		FormViewService,
		ListViewService,
		MetadataService,
	],
	declarations: [

    PageFooterComponent,
      PoweredByComponent
  ],
})
export class SharedModule {
}
