import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {JobsService} from '../../../protected/jobs/services/jobs.service';

@Injectable()
export class NoteService {
	private _api: string =  '/notes';

	public constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
		private jobSvc: JobsService,
	) {
	}

	/** @deprecated */
	public createNoteForIncident(incidentId: string, note: string, attachments: FileList | null): Promise<void> {
		return this.jobSvc.createNote(incidentId, note, attachments);
	}

	public downloadNote(id: string): Promise<void> {
		const download = window.open(`${this._api}/notes/${id}/download`, '_blank');
		return Promise.resolve();
	}
}
