import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, first, shareReplay, switchMap, tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {Contract} from './interfaces/contract';

@Injectable({
	providedIn: 'root',
})
export class ContractService implements Resolve<Contract[]> {
	readonly #contracts: Observable<Contract[]> =this.auth.state.pipe(
		filter(state => state === 'authenticated'),
		switchMap(() => {
			const headers = new HttpHeaders({
				Authorization: this.auth.getAuthorization(),
			});

			return this.http.get<Contract[]>(`/api/contracts`, {headers});
		}),

		tap(contracts => {
			const selectedId = localStorage.getItem('selectedContract')!;
			contracts.sort((a, b) => a.name.localeCompare(b.name));
			const contract = contracts.find(c => c.id === selectedId) ?? contracts[0];
			this.auth.changeCurrentContract(contract);
		}),

		shareReplay(1),
		first(),
	);

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Contract[] | Observable<Contract[]> | Promise<Contract[]> {
		return this.getContracts();
	}

	public getContracts() {
		return this.#contracts;
	}

	getContractPriorities(contractId: string) {
		return this.http.get<{ id: string; label: string; }[]>(`/api/contracts/${contractId}/priorities`);
	}
	getContractSites(contractId: string) {
		return this.http.get<{ id: string; label: string; }[]>(`/api/contracts/${contractId}/sites`);
	}
}
