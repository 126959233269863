import {HttpResponse} from '@angular/common/http';
import {ErrorHandler, EventEmitter, Injectable} from '@angular/core';
//import 'zone.js';
import {AuthenticationService} from '../../../authentication.service';

interface ZoneAwarePromiseError extends Error {
	promise: Promise<any>;
	rejection: any;
	task: any
	zone: any;
}

@Injectable()
export class ErrorHandlerService {
	public errors: EventEmitter<Error> = new EventEmitter<Error>();

	public constructor(
		// protected router: Router,
		// protected activatedRoute: ActivatedRoute,
		protected auth: AuthenticationService,
		// protected toast: MatSnackBar,
	) {
	}

}

@Injectable()
export class ICErrorHandler extends ErrorHandler {

	public constructor(
		protected errorService: ErrorHandlerService,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public override async handleError(error: any): Promise<void> {
		try {
			if (this.isPromiseRejection(error)) {
				return this.handleError(error.rejection);
			}

			console.error('Caught', '"' + error.name + '"', error);

			if (error instanceof HttpResponse) {
				if (error.status === 403) {
					await this.auth.logout();
					location.reload();
					// await this.router.navigate(['/login'], {queryParams: {returnUrl: this.activatedRoute.snapshot.url}});
					// this.toast.show('Please login to continue', 5000);
					return;
				}
			}

			if (error instanceof Error) {
				if (error.name.startsWith('IndexSizeError')) {
					return;
				}
				if (error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
					return;
				}

				this.errorService.errors.next(error);
				return;
			} else {
				this.errorService.errors.next(new Error(error));
				return;
			}

		}

		catch (ex) {
			console.error(ex);
			alert('Fatal error while handling error');
		}


		console.error('Uncaught', error);
	}

	private isPromiseRejection(error: any): error is ZoneAwarePromiseError {
		return error.hasOwnProperty('promise') && error.hasOwnProperty('rejection');
	}


}
