import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DialogDirective} from './dialog.directive';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [DialogDirective],
	declarations: [DialogDirective],
})
export class DirectivesModule {
}
