
<ngx-charts-chart
        [view]="[width, height]"
        [showLegend]="legend"
        [legendOptions]="legendOptions"
        [activeEntries]="activeEntries"
        [animations]="animations"
        (legendLabelActivate)="onActivate($event, undefined!, true)"
        (legendLabelDeactivate)="onDeactivate($event, undefined!, true)"
        (legendLabelClick)="onClick($event)"
>
    <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g
                ngx-charts-x-axis
                *ngIf="xAxis"
                [xScale]="xScale"
                [dims]="dims"
                [showLabel]="showXAxisLabel"
                [labelText]="xAxisLabel"
                [trimTicks]="trimXAxisTicks"
                [rotateTicks]="rotateXAxisTicks"
                [maxTickLength]="maxXAxisTickLength"
                [tickFormatting]="xAxisTickFormatting"
                [ticks]="xAxisTicks"
                (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
                ngx-charts-y-axis
                *ngIf="yAxis"
                [yScale]="yScale"
                [dims]="dims"
                [showGridLines]="showGridLines"
                [showLabel]="showYAxisLabel"
                [labelText]="yAxisLabel"
                [trimTicks]="trimYAxisTicks"
                [maxTickLength]="maxYAxisTickLength"
                [tickFormatting]="yAxisTickFormatting"
                [ticks]="yAxisTicks"
                (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g *ngIf="!isSSR">
            <svg:g
                    *ngFor="let group of results; trackBy: trackBy"
                    [@animationState]="'active'"
                    [attr.transform]="groupTransform(group)"
            >
                <svg:g
                        ngx-charts-series-vertical
                        [type]="barChartType.Normalized"
                        [xScale]="xScale"
                        [yScale]="yScale"
                        [activeEntries]="activeEntries"
                        [colors]="colors"
                        [series]="group.series"
                        [dims]="dims"
                        [gradient]="gradient"
                        [tooltipDisabled]="tooltipDisabled"
                        [tooltipTemplate]="tooltipTemplate"
                        [seriesName]="group.name"
                        [animations]="animations"
                        [noBarWhenZero]="noBarWhenZero"
                        (select)="onClick($event, group)"
                        (activate)="onActivate($event, group)"
                        (deactivate)="onDeactivate($event, group)"
                />
            </svg:g>
        </svg:g>
        <svg:g *ngIf="isSSR">
            <svg:g *ngFor="let group of results; trackBy: trackBy" [attr.transform]="groupTransform(group)">
                <svg:g
                        ngx-charts-series-vertical
                        [type]="barChartType.Normalized"
                        [xScale]="xScale"
                        [yScale]="yScale"
                        [activeEntries]="activeEntries"
                        [colors]="colors"
                        [series]="group.series"
                        [dims]="dims"
                        [gradient]="gradient"
                        [tooltipDisabled]="tooltipDisabled"
                        [tooltipTemplate]="tooltipTemplate"
                        [seriesName]="group.name"
                        [animations]="animations"
                        [noBarWhenZero]="noBarWhenZero"
                        (select)="onClick($event, group)"
                        (activate)="onActivate($event, group)"
                        (deactivate)="onDeactivate($event, group)"
                />
            </svg:g>
        </svg:g>
    </svg:g>
</ngx-charts-chart>