import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {PageToolbarComponent} from './page-toolbar.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
	],
	declarations: [PageToolbarComponent],
	exports: [PageToolbarComponent],
})
export class PageToolbarModule {
}
