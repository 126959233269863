<app-page-toolbar>
    <app-toolbar-title>Site > {{ site ? site.ri_name : null }}</app-toolbar-title>
    <app-toolbar-spacer></app-toolbar-spacer>

    <ng-container *ngIf="listLayout">
        <app-toolbar-csv-download
                (click)="downloadBatch()"
                [disabled]="listLayout.selectionModel.isEmpty()">
            Download Selected
        </app-toolbar-csv-download>
    </ng-container>
    
    <app-toolbar-button (click)="backButtonClick()" icon="/assets/icons/chevron_left_white.svg">Back to sites
    </app-toolbar-button>
</app-page-toolbar>

<nav class="page-nav">
    <div class="page-nav-item" routerLink="details" routerLinkActive="selected">details</div>
    <div class="page-nav-item" routerLink="visits" routerLinkActive="selected">visits</div>
    <div class="page-nav-item" routerLink="assets" routerLinkActive="selected">assets</div>
    <div class="page-nav-item" routerLink="paperwork" routerLinkActive="selected">documentation</div>
</nav>

<router-outlet (activate)="onActivate($event)"></router-outlet>
