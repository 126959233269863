export enum ListViewTypes {
	Jobs = 157410000,
	Sites,
	Visits,
	Assets,
	Paperwork,
	JobVisits,
	JobPaperwork,
	JobNotes,
	SiteJobs,
	SiteVisits,
	SiteAssets,
	SitePaperwork,
	VisitPaperwork,
}
