import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Entities} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {APIService} from '../../jobs/services/jobs.service';

@Injectable()
export class PaperworkService extends APIService {
	protected _api = `/api/paperwork`;

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	/**
	 * Unusual signature due to pluralization
	 */
	public getAllPaperwork(params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities('/', params);
	}
}
