///<reference types="@types/wicg-file-system-access"/>

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {GridColumn} from '../../../_shared/xml-list-layout/xml-list-layout.component';
import {AuthenticationService} from '../../../authentication.service';
import {Contract} from '../../../interfaces/contract';
import {CsvExportService} from '../../../modules/csv-export/csv-export.service';
import {Download} from '../../../modules/csv-export/download';
import {EntityList} from '../../_controllers/entity-list';
import {PaperworkService} from '../services/paperwork.service';
import {firstValueFrom} from "rxjs";


export const PaperworkReport = {
    getAttributeValue(entity: CrmEntity): string | null {
        const attr = entity.attributes.find(({key}) => key === 'ri_url');

        if (attr) {
            const url = attr.value;

            if (attr && typeof url === 'string' && url.trim().length > 0) {
                return url.trim();
            }
        }

        return null;
    },

    getValue(this: GridColumn, entity: CrmEntity) {
        const url = PaperworkReport.getAttributeValue(entity);

        return url ? 'Download' : 'Report Unavailable';
    },

    onClick(this: GridColumn, entity: CrmEntity) {
        const uri = PaperworkReport.getAttributeValue(entity);
        if (!uri) {
            return;
        }
        window.open(uri, '_blank');
    },
};

@Component({
    selector: 'app-paperworks-list',
    templateUrl: './paperworks-list.component.html',
    styleUrls: ['./paperworks-list.component.scss'],
})
export class PaperworksListComponent extends EntityList implements OnInit {
    public listViewType: ListViewTypes = ListViewTypes.Paperwork;

    public constructor(
        protected entityService: PaperworkService,
        protected entitySvc: PaperworkService,
        protected listViewSvc: ListViewService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected override toast: MatSnackBar,
        protected override auth: AuthenticationService,
        protected http: HttpClient,
        public csvExportService: CsvExportService,
    ) {
        super();
    }

    public override async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        this.listLayout.addColumn('report', 'Report', 100,
            PaperworkReport.getValue,
            PaperworkReport.onClick,
        );
    }

    public async downloadCSV() {
        const contract: Contract = this.auth.currentContract.getValue();
        const headers = new HttpHeaders().append('Authorization', this.auth.getAuthorization());
        const params = new HttpParams({fromString: `${this.generateQueryParams()}`}).set('contractId', contract.id);

        const csvExport = await this.http.get<Download>(`/api/paperwork/csv`, {
            headers,
            params,
        }).toPromise();

        this.csvExportService.addDownload(csvExport!);
    }

    public async downloadBatch() {
        const ids = this.listLayout.selectionModel.selected;
        let formData = new FormData();

        ids.forEach(id => formData.append('ids', id));


      this.listLayout.selectionModel.clear();

        let handle: any;

        try {
            handle = await window.showSaveFilePicker({
                suggestedName: 'batch.zip',
                excludeAcceptAllOption: true,
                types: [
                    {accept: {'application/zip': ['.zip']}, description: 'Zip Archive of PDF Files'},
                    {accept: {'application/pdf': ['.pdf']}, description: 'Single PDF File'},
                ]
            });
        }
        catch (e) {
          console.error(e);
            return;
        }

        if (handle == null) {
          console.error('No handle');
            return;
        }


        const blob = await firstValueFrom(this.http.post(`/api/batch-pdf/${handle.name}`, formData, {
            responseType: 'blob',
        }));

        const writable = await handle.createWritable({keepExistingData: false});

        await writable.write(blob);
        await writable.close();
    }

    protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
        return this.entitySvc.getAllPaperwork(params);
    }
}
