<div class="form-wrapper">
    <section *ngFor="let tab of tabs | tabIsVisible">
        <h3 *ngIf="tab.showLabel">{{ tab.label }}</h3>
        <div class="tab-columns">
            <div class="tab-column" *ngFor="let column of tab.columns">
                <div class="column-sections">
                    <div class="column-section" *ngFor="let section of column.sections">
                        <h4>{{ (section.showLabel) ? section.label : '' }}</h4>
                        <div class="section-rows">
                            <div class="section-row" *ngFor="let row of section.rows">
                                <label *ngIf="row.cell && row.cell.control_id"
                                       for="{{ row.cell.control_id }}">
                                    {{ row.cell.label }}
                                </label>
                                <input
                                    *ngIf="row.cell && row.cell.control_id"
                                    id="{{ row.cell.control_id }}"
                                    [title]="getEntityValue(row.cell.control_id)"
                                    [value]="getEntityValue(row.cell.control_id)"
                                    readonly
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
