import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {from as observableFrom, Subject} from 'rxjs';

import {debounceTime, distinctUntilKeyChanged, filter, tap} from 'rxjs/operators';
import {LinkedEntityReference, SavedQuery} from '../../fetch-xml/saved-query';
import {ListView} from '../../interfaces/list-view';
import {CrmAttribute, EntityReference} from '../crm/attribute';
import {CrmEntity, PaginatedEntity} from '../crm/entity';
import {FilterDialogComponent} from '../filter-dialog/filter-dialog.component';
import {FilterTypes} from '../filter-dialog/filter-types.enum';
import {ColumnFilter} from '../filter-dialog/interfaces/column-filter';
import {ColumnSort} from '../filter-dialog/interfaces/column-sort';
import {Attribute, MetadataService} from '../services/metadata.service';
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {SelectionModel} from "@angular/cdk/collections";

export interface GridColumn {
  name: string;
  label: string;
  width: number;
  type: FilterTypes;
  entity?: string;
  entityAlias?: string;
  hidden: boolean,

  custom: boolean;
  getValue: (entity: CrmEntity) => string;
  onClick: (entity: CrmEntity) => void;

  filterable: boolean,

  sortable: boolean,
  sort: 'asc' | 'desc' | null;

  values: { label: string; value: any; }[]
}

@Component({
  selector: 'app-xml-list-layout',
  templateUrl: './xml-list-layout.component.html',
  styleUrls: ['./xml-list-layout.component.scss'],
})
export class XmlListLayoutComponent implements OnChanges {
  @Input() public listView!: ListView;

  @Input()
  set showCheckbox(value: unknown) {
    this.#showCheckbox = coerceBooleanProperty(value);
  }

  get showCheckbox(): boolean {
    return this.#showCheckbox;
  }

  #showCheckbox: boolean = false;

  selectionModel = new SelectionModel<string>(true, []);

  @Output() public listViewChange = new EventEmitter<ListView>();
  @Input() public entityList!: CrmEntity[];
  @Output() public entityClicked = new EventEmitter<CrmEntity>();

  public columnFilter: ColumnFilter = null!;
  public columnSort: ColumnSort = null!;
  public FilterTypes = FilterTypes;

  @ViewChild(FilterDialogComponent, {static: true}) protected filterDialog!: FilterDialogComponent;
  private _columns: GridColumn[] = [];
  private _extraColumns: GridColumn[] = [];
  private attributes: Attribute[] = [];
  private missingAttributes = new Set();
  private entityTypes = new Set<string>();

  private $generateView = new Subject<ListView>();
  private mutators: { [index: string]: Function } = {};

  constructor(
    private metadataSvc: MetadataService,
  ) {
    this
      .$generateView.pipe(
      filter(v => v !== undefined),
      distinctUntilKeyChanged('savedQueryId'),
      debounceTime(500),
      tap(() => observableFrom(this.generateView())))
      .subscribe();
  }

  public get columns(): GridColumn[] {
    return Array.of(...this._columns, ...this._extraColumns);
  }

  public get visibleColumns(): GridColumn[] {
    return this.columns.filter(c => !c.hidden);
  }

  public get fetchXml(): SavedQuery {
    return this.listView.fetchXml;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('listView')) {
      this.$generateView.next(changes['listView'].currentValue);
    }
  }

  public addColumn(
    name: string,
    label: string,
    width: number,
    getValue: (entity: CrmEntity) => string | null,
    onClick: (entity: CrmEntity) => void,
  ): void {
    this._extraColumns.push(<GridColumn>{
      name,
      label,
      width,

      type: null!,
      hidden: false,

      custom: true,
      getValue,
      onClick,

      filterable: false,

      sortable: false,
      sort: null!,
      values: null!,
    });
  }

  public cellClick(event: UIEvent, entity: CrmEntity, column: GridColumn): void {
    event.stopPropagation();
    column.onClick(entity);
  }

  public rowClick(entity: CrmEntity): void {
    this.entityClicked.emit(entity);
  }

  public updateFilter(columnFilter: ColumnFilter): void {
    if (columnFilter) {
    } else {
      this.columnFilter = null!;
    }

    this.listViewChange.emit(this.listView);
  }

  public modifyColumn(columnName: string, getValue: (value: unknown) => void) {
    this.mutators[columnName] = getValue;
  }

  protected sortBy(column: GridColumn): void {
    if (this.columnSort && this.columnSort.column === column.name) {
      if (this.columnSort.direction === 'asc') {
        this.columnSort.direction = 'desc';
      } else {
        this.columnSort.direction = 'asc';
      }
    } else {
      this.columnSort = {
        entity: column.entity!,
        column: column.name,
        direction: 'desc',
      };
    }

    this.listViewChange.emit(this.listView);
  }

  protected filterBy(column: GridColumn): void {
    if (this.columnFilter && this.columnFilter.column.name === column.name) {

    } else {
      const splitName = column.name.split('.');
      const columnName = splitName.length > 1 ? splitName[splitName.length - 1] : column.name;

      this.columnFilter = {
        entity: column.entity!,
        columnName: columnName,
        type: column.type,
        operator: null!,
        condition: null!,
        column,
      };
    }

    this.filterDialog.openDialog(this.columnFilter);
  }

  /**
   * Converts supplied layout xml into html
   */
  private async generateView(): Promise<void> {
    try {
      this._columns = [];
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.listView.layoutXml, 'application/xml');

      this.attributes = [];

      const attributes = await this.metadataSvc.getAttributes(this.listView.fetchXml.entityName);
      this.attributes.push(...attributes);

      for (const entity of this.listView.fetchXml.linkedEntities) {
        const attributes = await this.metadataSvc.getAttributes(entity.name);
        this.attributes.push(...attributes);
      }

      if (this.attributes.length === 0) return;

      const rows = doc.querySelectorAll('grid > row');

      for (const child of Array.from(rows)) {
        this._columns = this.parseRow(child);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  private parseRow(rowNode: Element): GridColumn[] {
    const columns = [];

    for (const child of Array.from(rowNode.children)) {
      switch (child.nodeName) {
        case 'cell': {
          columns.push(this.parseCell(child));
          break;
        }
        default:
          console.warn('String unhandled', child);
      }
    }
    return columns;
  }

  protected getEntityValue(entity: CrmEntity, cell: GridColumn): string {
    if (entity === undefined) return '?';

    if (cell.custom) {
      return cell.getValue(entity);
    }
    let attr: CrmAttribute;

    const name1 = cell.entityAlias ? cell.entityAlias + '.' + cell.name : cell.name;

    attr = entity.formattedValues.find(a => a.key === name1)!;

    if (attr === undefined) {
      attr = entity.attributes.find(a => a.key === name1)!;
    }

    if (attr === undefined && this.missingAttributes.has(name1) === false) {
      this.missingAttributes.add(name1);
      console.warn('No value for attribute', name1);
    }

    if (attr === undefined) return null!;

    let value = attr.value;

    if (typeof attr.value === 'object') {
      if (attr.value.hasOwnProperty('attributeLogicalName')) {
        const innerValue = <EntityReference>attr.value;

        this.entityTypes.add(innerValue.entityLogicalName);

        value = typeof innerValue.value === 'object' ? innerValue.value.name : innerValue.value;
      } else if (attr.value.hasOwnProperty('entityName')) {
        const innerValue = <PaginatedEntity>attr.value;

        // Don't know what it is
        if (innerValue.entityName === 'activityparty')
          value = this.getEntityValue(
            innerValue.entities[0],
            Object.assign({}, cell, {name: 'partyid'}),
          );
        else
          value = `Can't get value`;
      } else {
        value = `Can't get value`;
      }
    }

    if (cell.type === FilterTypes.Date) {
      const rawAttr = entity.attributes.find(a => a.key === name1)!;
      if (typeof rawAttr.value === 'string') {
        if (`${attr.value}`.includes(':')) {
          value = moment(rawAttr.value).format('DD/MM/YYYY H:mm A');
        } else {
          value = moment(rawAttr.value).format('DD/MM/YYYY');
        }
      } else if (typeof rawAttr.value === 'object') {
        const entityRef = rawAttr.value as EntityReference;

        if (typeof entityRef.value === 'string') {
          if (`${attr.value}`.includes(':')) {
            value = moment(entityRef.value).format('DD/MM/YYYY H:mm A');
          } else {
            value = moment(entityRef.value).format('DD/MM/YYYY');
          }
        } else {
          console.warn(attr.value);
          value = 'Cannot display data';
        }
      } else {
        console.warn(attr.value);
        value = 'Cannot display data';
      }
    }

    if (this.mutators[attr.key] !== undefined) {
      value = this.mutators[attr.key](value);
    }

    return `${value}`;
  }

  private parseCell(cellNode: Element): GridColumn {
    const cell: GridColumn = {
      name: null!,
      label: null!,
      width: null!,
      type: null!,

      entity: null!,
      entityAlias: null!,

      custom: false,
      getValue: null!,
      onClick: null!,

      filterable: false,

      sortable: true,
      sort: null,

      hidden: false,
      values: null!,
    };

    let alias: string | null = null;
    let entity: LinkedEntityReference | null = null;

    let name = cellNode.getAttribute('name');
    let attribute = this.attributes.find(a => a.name === name);
    if (!attribute) {
      [alias, name] = name!.split('.');
      entity = this.fetchXml.linkedEntities.find(e => e.alias === alias) ?? null;

      attribute = this.attributes.find(a => a.name === name);
    }

    if (!attribute) {
      return cell;
    }

    cell.name = name!;
    cell.entity = alias && entity ? entity.name : this.listView.fetchXml.entityName;
    cell.entityAlias = alias!;
    cell.label = attribute.label;
    cell.width = parseInt(cellNode.getAttribute('width')!, 10) || 100;

    cell.type = attribute.type;
    // cell.type = alias ? null : attribute.type;
    cell.values = attribute.values;
    cell.sort = this.parseFetchXmlForOrder(cell.name);


    if (cellNode.hasAttribute('disableSorting')) {
      const disableSorting = cellNode.getAttribute('disableSorting');
      cell.sortable = disableSorting !== '1';
      cell.filterable = disableSorting !== '1';
    }

    if (cellNode.hasAttribute('ishidden')) {
      const isHidden = cellNode.getAttribute('ishidden');
      cell.hidden = isHidden === '1' || cell.width === 25;
    } else {
      cell.hidden = cell.width === 25;
    }


    return cell;
  }

  private parseFetchXmlForOrder(cellName: string) {
    let sortBy = null;

    if (this.columnSort === null && cellName === this.listView.fetchXml.orderColumn) {
      sortBy = this.listView.fetchXml.orderDirection;

      this.columnSort = {
        entity: this.listView.fetchXml.entityName,
        column: this.listView.fetchXml.orderColumn,
        direction: this.listView.fetchXml.orderDirection,
      };
    }

    return sortBy as 'desc' | 'asc';
  }

  toggle(id: string) {
    this.selectionModel.toggle(id);
  }

  isPageSelected() {
    return this.entityList.every(row => this.selectionModel.isSelected(row.id));
  }

  selectAll() {
    if (this.entityList.every(row => this.selectionModel.isSelected(row.id))) {
      this.selectionModel.deselect(...this.entityList.map(row => row.id));
      return;
    }

    for (let crmEntity of this.entityList) {
      this.selectionModel.select(crmEntity.id);
    }
  }
}
