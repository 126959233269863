<dialog
    *ngIf="visit"
    #newReportDialog
    class="new-report"
>
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner">
        <h1>Add new Report</h1>
        <form [formGroup]="newReportForm" class="form" (submit)="submit()">
            <div class="form-main">
                <div class="inputs">
                    <label>Job Number
                        <input [value]="job" readonly>
                    </label>

                    <label>Site
                        <input [value]="site" readonly>
                    </label>

                    <label>Engineer
                        <input [value]="engineer.name" readonly>
                    </label>
                </div>

                <div class="inputs">
                    <label>Date
                        <input type="date" formControlName="date">
                        <!--<select formControlName="date">-->
                        <!--<option value="1" selected>Low</option>-->
                        <!--<option value="2">Medium</option>-->
                        <!--<option value="3">High</option>-->
                        <!--</select>-->
                    </label>

                    <label>Time
                        <select formControlName="time">
                            <option *ngFor="let time of times" value="{{time}}">
                                {{time}}
                            </option>
                        </select>
                    </label>

                </div>
            </div>
            <div class="form-sidebar">
                <div class="inputs">
                    <label>Report Type
                        <select formControlName="type">
                            <ng-container *ngIf="$types | async as types; else noReportTypes">
                                <option *ngFor="let type of types"
                                        [value]="type.entityName">{{type.displayName}}
                                </option>
                            </ng-container>

                            <ng-template #noReportTypes>
                                <option disabled selected>NO TYPES FOUND</option>
                            </ng-template>
                        </select>
                    </label>

                    <label>Attach Report
                        <input
                            #documents
                            name="documents"
                            formControlName="documents"
                            type="file"
                            (change)="onDocumentChange(documents)"
                        >

                        <div class="show-docs">
                            <ul>
                                <li *ngFor="let doc of docs">{{doc}}</li>
                            </ul>
                        </div>
                    </label>
                </div>
                <div class="controls">
                    <button class="success" [class.disabled]="!newReportForm.valid">submit</button>
                </div>
            </div>
        </form>
    </div>
</dialog>
