import {Directive, ElementRef} from '@angular/core';
import {NgSelectOption} from '@angular/forms';

@Directive({
  selector: '[appOption],select>option'
})
export class OptionDirective {

  constructor(
  	public ref:ElementRef<HTMLOptionElement>,
  	public option:NgSelectOption,
  ) {
  }

  get label() {
  	return this.ref.nativeElement.label;
  }
  get id() {
  	return this.option.id;
  }
  get ngValue() {
  	return this.option.ngValue;
  }
  get value() {
  	return this.option.value;
  }

}
