import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmEntity, Entities} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {Contract} from '../../../interfaces/contract';
import {firstValueFrom} from "rxjs";

export abstract class APIService {
	protected abstract _api: string;
	protected abstract auth: AuthenticationService;
	protected abstract http: HttpClient;

	protected async retrieveEntities(
		url: string,
		params: URLSearchParams,
		headers: HttpHeaders = new HttpHeaders(),
	): Promise<Entities> {
		headers = headers.append('Authorization', this.auth.getAuthorization());

		const currentContract: Contract = this.auth.currentContract.getValue();
		params.set('contractId', currentContract.id);

		const res = await firstValueFrom(this.http.get<Entities>(`${this._api}${url}?${params}`, {
			headers,
			observe: 'response',
		}));

		return Object.assign(res.body as Array<CrmEntity>, {
			totalRecordCount: parseInt(res.headers.get('x-count')!) || -1,
			totalRecordCountLimitExceeded: res.headers.get('x-toomanyrecords') === 'true',
			moreRecords: res.headers.get('x-morerecords') !== 'false',
		});
	}
}

@Injectable()
export class JobsService extends APIService {
	protected _api = '/api/incidents';

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public getJobs(params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities('/', params);
	}

	public getJob(id: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			'Authorization': this.auth.getAuthorization(),
		});

		return firstValueFrom(this.http.get<CrmEntity>(`${this._api}/${id}`, {headers}));
	}

	public getJobNotes(id: string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/${id}/notes`, params);
	}

	public getJobPaperwork(id: string, params: URLSearchParams): Promise<CrmEntity[]> {
		return this.retrieveEntities(`/${id}/paperwork`, params);
	}

	public getJobVisits(id: string, params: URLSearchParams): Promise<CrmEntity[]> {
		const headers = new HttpHeaders({
			'Authorization': this.auth.getAuthorization(),
		});

		const currentContract: Contract = this.auth.currentContract.getValue();
		params.set('contractId', currentContract.id);

		return firstValueFrom(this.http.get<CrmEntity[]>(`${this._api}/${id}/visits?${params}`, {headers}));
	}

	public createNote(incidentId: string, message: string, attachments: FileList | null): Promise<void> {
		const payload = new FormData();
		payload.append('message', message);

		if (attachments) {
			for (const attachment of Array.from(attachments)) {
				payload.append('file', attachment);
			}
		}

		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return this.http.post<void>(`${this._api}/${incidentId}/notes/create`, payload, {headers})
			.toPromise();
	}
}
