import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../../ri-loader-component/loader.module';
import {SharedModule} from '../../_shared/shared.module';
import {ToolbarModule} from '../../toolbar/toolbar.module';
import {AssetsListComponent} from './assets-list/assets-list.component';
import {AssetService} from './services/asset.service';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetNavWrapperComponent } from './asset-nav-wrapper/asset-nav-wrapper.component';

@NgModule({
	imports:      [
		CommonModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		RouterModule,
		LoaderModule,
		ToolbarModule,
	],

	declarations: [
		AssetsListComponent,
		AssetDetailsComponent,
		AssetNavWrapperComponent,
	],

	providers: [
		AssetService,
	],
})
export class AssetsModule {
}
