import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import CaughtError from '../caught-error';

@Component({
	selector:    'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls:   ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
	@ViewChild('errorDialog', { static: true }) dialog!: ElementRef;
	@Input('error') err: any;
	@Output() opened: EventEmitter<void> = new EventEmitter<void>();
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	public get error() {
		if (this.err instanceof CaughtError) return this.err;
		else return new CaughtError('Oops, something went wrong!', 'There was an error while displaying this web page.', this.err);
	}

	public closeDialog(): void {
		this.dialog.nativeElement.close();
		this.closed.emit(this.err);
	}
}
