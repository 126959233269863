
import {map, filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class IsLoggedIn implements CanActivate {
	public constructor(
		private router: Router,
		private authSvc: AuthenticationService,
	) {
	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// return Observable.of(true)

		return this.authSvc.state.pipe(
			filter(state => state !== 'pending'),
			map(state => {
				if (state === 'authenticated') {
					return true;
				} else {
					this.router.navigate(['/login']);
					return false;
				}
			}),);


		// if (this.authSvc.isAuthenticated()) {
		// 	return true;
		// }
		// else {
		// 	this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
		// 	return false;
		// }
	}
}
