import {Component, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import CaughtError from '../../../../../_shared/caught-error';
import {OptionSetValue} from '../../../../../_shared/crm/attribute';
import {CrmEntity} from '../../../../../_shared/crm/entity';
import {VisitService} from '../../../services/visit.service';

export enum VisitStatus {
	Draft = 157410000,
	SentNotReceived,
	SentReceived,
	OnRoute,
	OnSite,
	ReturnVisitRequired,
	WorkComplete,
	JobPaused,
	Cancelled,
	NoAccess,
	Rejected,
	Allocated,
	OffSite
}

export enum VisitUpdateStatus {
	Draft = 1,
	SentNotReceived = 3,
	SentReceived = 4,
	OnRoute = 6,
	OnSite = 7,
	ReturnVisitRequired = 157410002,
	WorkComplete = 8,
	JobPaused = 157410001,
	Cancelled = 9,
	NoAccess = 10,
	Rejected = 157410003,
	Allocated = 157410004,
	OffSite = 157410000,
	Tentitive = 2,
	Rescheduled = 157410005
}

export const VisitStatusLabels: Record<VisitStatus, string> = {
	[VisitStatus.Draft]: 'Draft',
	[VisitStatus.SentNotReceived]: 'Sent Not Received',
	[VisitStatus.SentReceived]: 'Sent & Received',
	[VisitStatus.OnRoute]: 'On-Route',
	[VisitStatus.OnSite]: 'On-Site',
	[VisitStatus.ReturnVisitRequired]: 'Return Visit Required',
	[VisitStatus.WorkComplete]: 'Work Complete',
	[VisitStatus.JobPaused]: 'Job Paused',
	[VisitStatus.Cancelled]: 'Cancelled',
	[VisitStatus.NoAccess]: 'No Access',
	[VisitStatus.Rejected]: 'Rejected',
	[VisitStatus.Allocated]: 'Allocated',
	[VisitStatus.OffSite]: 'Off-Site',
};

@Component({
	selector: 'app-visit-status',
	templateUrl: './visit-status.component.html',
	styleUrls: ['./visit-status.component.scss'],
})
export class VisitStatusComponent implements OnInit {

	public visitEntity!: CrmEntity;
	visitStatusForm!: FormGroup;

	public visitStatuses: VisitStatus[] = [
		VisitStatus.Draft,
		VisitStatus.SentNotReceived,
		VisitStatus.SentReceived,
		VisitStatus.OnRoute,
		VisitStatus.OnSite,
		VisitStatus.ReturnVisitRequired,
		VisitStatus.WorkComplete,
		VisitStatus.JobPaused,
		VisitStatus.Cancelled,
		VisitStatus.NoAccess,
		VisitStatus.Rejected,
		VisitStatus.Allocated,
		VisitStatus.OffSite,
	];
	public visitStatusLabels = VisitStatusLabels;

	@Output('closed') closed = new Subject<void>();

	constructor(
		private builder: FormBuilder,
		private visitService: VisitService,
	) {
		this.initForm();
	}

	ngOnInit() {
	}

	public openDialog(visitEntity: CrmEntity) {
		this.visitEntity = visitEntity;
		this.initForm();
	}

	public async updateVisitStatus(): Promise<void> {
		if (this.visitStatusForm.dirty && this.visitStatusForm.valid) {
			try {
				await this.visitService.updateVisitStatus(this.visitEntity.id, this.visitStatusForm.value);
				this.visitStatusForm.reset();
				this.closeDialog();
			} catch (ex) {
				throw CaughtError.Error('Failed', 'Unable to update status', ex);
			}
		} else {
			this.closeDialog();
		}

	}

	public closeDialog(): void {
		this.closed.next();
	}

	private initForm() {
		if (this.visitEntity) {

			const status = this.visitEntity.attributes.find(attr => attr.key === 'statuscode')!;

			const value = (<OptionSetValue>status.value).value;
			let visitUpdateStatus: string = status
				? VisitUpdateStatus[value]
				: VisitUpdateStatus[VisitUpdateStatus.Draft];

			this.visitStatusForm = this.builder.group({
				status: [VisitStatus[visitUpdateStatus as any] as any, Validators.required],
				details: [''],
			});
		}
		else {
			this.visitStatusForm = this.builder.group({
				status: ['', Validators.required],
				details: [''],
			});
		}
	}
}
