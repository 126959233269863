<div class="custom-select" (click)="selectOpen = !selectOpen">
    <div class="select-icon" [class.open]="selectOpen"></div>
    {{ select.value | json }}

    <div class="custom-dropdown" [class.open]="selectOpen">
        <div class="dropdown-btn" *ngFor="let option of options.changes | async;" (click)="handleOptionClick(option)">
           {{ option.label }}
        </div>
    </div>
</div>
