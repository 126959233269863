<h3>Note</h3>

<div class="single-note">
    <div class="details">
        <label>Created By
            <input [value]="note.createdby.name" readonly>
        </label>

        <label>Subject
            <input [value]="note.subject" readonly>
        </label>

        <label>Created Date
            <input [value]="note.createdon | date:'dd MMM, y HH:mm'" readonly>
        </label>

        <label>Attachment
            <input (click)="openAttachment()" [value]="note.filename" readonly>
        </label>

    </div>
    <div class="note-container">
        <label class="expanded">Note
            <textarea [value]="note.notetext" readonly></textarea>
        </label>
    </div>
</div>
<div class="controls">
    <div class="spacer"></div>
    <!--<div class="controls-area">-->
    <!--<button class="back" [class.disabled]="!prevLink" (click)="goToNote('test')">-->
    <!--<div class="icon"></div>-->
    <!--prev note-->
    <!--</button>-->
    <!--<button class="next" [class.disabled]="!nextLink" (click)="goToNote('test')">-->
    <!--<div class="icon"></div>-->
    <!--next note-->
    <!--</button>-->
    <!--</div>-->
</div>