import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../authentication.service';
import {Contract} from '../../interfaces/contract';
import {FormView} from '../../interfaces/form-view';
import {FormViewTypes} from '../form-view-types.enum';
import {firstValueFrom} from "rxjs";

export interface SystemForm {
	formId: string;
	name: string;
	formXml: string;
}

@Injectable()
export class FormViewService {
	private _api =  '/api/form-views';

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	public getViews(view_type: FormViewTypes): Promise<FormView[]> {
		const params = new URLSearchParams();
		params.set('type', view_type.toString());

		const currentContract: Contract = this.auth.currentContract.getValue();
		params.set('contractId', currentContract.id);

		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<FormView[]>(`${this._api}?${params}`, {headers}));
	}

}
