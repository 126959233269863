<ri-loader></ri-loader>
<app-page-toolbar>
    <app-toolbar-title>Jobs > {{ job ? job.title : '' }}</app-toolbar-title>
    <app-toolbar-spacer></app-toolbar-spacer>
    
    <ng-container *ngIf="listLayout">
        <app-toolbar-csv-download
                (click)="downloadBatch()"
                [disabled]="listLayout.selectionModel.isEmpty()">
            Download Selected
        </app-toolbar-csv-download>
    </ng-container>
    
    <app-toolbar-button (click)="backButtonClick()" icon="/assets/icons/chevron_left_white.svg">Back to jobs
    </app-toolbar-button>
</app-page-toolbar>

<nav class="page-nav">
    <div class="page-nav-item" routerLink="details" routerLinkActive="selected">details</div>
    <div class="page-nav-item" routerLink="visits" routerLinkActive="selected">visits</div>
    <div class="page-nav-item" routerLink="paperwork" routerLinkActive="selected">documentation</div>
</nav>

<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-new-note-dialog (saving)="handleNewNoteSaving($event)" [incidentId]="id"></app-new-note-dialog>
