import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {FormViewTypes} from '../../../_shared/form-view-types.enum';
import {FormViewService} from '../../../_shared/services/form-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityForm} from '../../_controllers/entity-form';
import {VisitService} from '../services/visit.service';

@Component({
	selector: 'app-visit-details',
	templateUrl: './visit-details.component.html',
	styleUrls: ['./visit-details.component.scss'],
})
export class VisitDetailsComponent extends EntityForm {
	public formViewType: FormViewTypes = FormViewTypes.VisitDetails;

	constructor(
		protected formViewService: FormViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		private entitySvc: VisitService,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntity(guid: string): Promise<CrmEntity> {
		return this.entitySvc.getVisit(guid);
	}


}
