export class FetchXml {
	public [Symbol.toStringTag] = 'FetchXml';

	protected document: Document;

	protected constructor(document: Document | string) {
		if (typeof document === 'string') {
			const parser = new DOMParser();
			this.document = parser.parseFromString(document, 'text/xml');
		} else {
			this.document = document;
		}
	}

	public toString(): string {
		const serializer = new XMLSerializer();
		const xmlString = serializer.serializeToString(this.document);
		return xmlString;
	}
}
