import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../../ri-loader-component/loader.module';
import {SharedModule} from '../../_shared/shared.module';
import {ToolbarModule} from '../../toolbar/toolbar.module';
import {VisitService} from './services/visit.service';
import {VisitDetailsComponent} from './visit-details/visit-details.component';
import {VisitJobComponent} from './visit-job/visit-job.component';
import {VisitNavWrapperComponent} from './visit-nav-wrapper/visit-nav-wrapper.component';
import {VisitPaperworkComponent} from './visit-paperwork/visit-paperwork.component';
import {VisitSiteComponent} from './visit-site/visit-site.component';
import {VisitStatusService} from './visits-list/popup/visit-status.service';
import {VisitStatusComponent} from './visits-list/popup/visit-status/visit-status.component';
import {VisitsListComponent} from './visits-list/visits-list.component';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		RouterModule,
		LoaderModule,
		ToolbarModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations: [
		VisitsListComponent,
		VisitDetailsComponent,
		VisitNavWrapperComponent,
		VisitJobComponent,
		VisitSiteComponent,
		VisitPaperworkComponent,
		VisitStatusComponent,
	],
	providers: [VisitService, VisitStatusService],
	entryComponents: [VisitStatusComponent],
})
export class VisitsModule {
}
