
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../authentication.service';
import {FilterTypes} from '../filter-dialog/filter-types.enum';
import {firstValueFrom} from "rxjs";

export interface AttributeResponse {
	name: string;
	label: string;
	type: string;
	values: any;
}

export interface Attribute {
	name: string;
	label: string;
	type: FilterTypes;
	values: any;
}

@Injectable()
export class MetadataService {
	private _unknownTypes = new Set<string>();

	public constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	public getAttributes(entity: string): Promise<Attribute[]> {
		return firstValueFrom(this.http.get<AttributeResponse[]>( `/api/metadata/${entity}/attributes`).pipe(
			map<AttributeResponse[], Attribute[]>(json => {
				return json.map(attr => ({
					name: attr.name,
					label: attr.label,
					type: this.lookupType(attr.type, attr),
					values: attr.values,
				}));
			})));
	}

	public getAttribute(entity: string, attribute: string): Promise<Attribute> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<AttributeResponse>( `/api/metadata/${entity}/${attribute}`, {headers}).pipe(
			map<AttributeResponse, Attribute>(json => {
				const attribute = {
					name: json.name!,
					label: json.label!,
					type: this.lookupType(json.type, json)!,
					values: json.values!,
				};

				return attribute;
			})));
	}

	private lookupType(name: string, attr: AttributeResponse): FilterTypes {
		switch (name) {
			case 'BooleanType':
				return FilterTypes.Boolean;
			case 'StringType':
				return FilterTypes.String;
			case 'DateTimeType':
				return FilterTypes.Date;
			case 'DecimalType':
			case 'IntegerType':
			case 'BigIntType':
			case 'MoneyType':
			case 'DoubleType':
				return FilterTypes.Number;
			case 'VirtualType':
				return FilterTypes.Virtual;
			case 'UniqueidentifierType':
				return FilterTypes.Entity;
			case 'PartyListType':
				return FilterTypes.PartyList;
			case 'PicklistType':
				return FilterTypes.PickList;
			case 'LookupType':
				return FilterTypes.Lookup;
			default:
				if (this._unknownTypes.has(name) === false) {
					console.warn('Unknown type', name, 'for attribute', attr.name, '"', attr.label, '"');
					this._unknownTypes.add(name);
				}
				return null!;
		}
	}
}
