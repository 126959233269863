<ri-loader></ri-loader>

<app-page-toolbar>
    <app-toolbar-icon (click)="back()" src="assets/icons/chevron_left_blue.svg" title="Back"></app-toolbar-icon>

    <app-toolbar-title>Child Assets</app-toolbar-title>

<!--    <app-toolbar-filter [selectedView]="selectedView" (selectedViewChanged)="changeView($event)"-->
<!--                        [filter]="filter"></app-toolbar-filter>-->
    <app-toolbar-spacer></app-toolbar-spacer>

    <app-toolbar-icon (click)="handleRefreshClick()" src="assets/icons/refresh.svg"></app-toolbar-icon>
</app-page-toolbar>

<app-xml-list-layout
    [(listView)]="selectedView"
    [entityList]="entities"
    (listViewChange)="handleViewChange()"
></app-xml-list-layout>

<app-page-footer>
  <app-powered-by/>
  <app-pagination [currentPage]="currentPage" [maxRecords]="maxRecords" [records]="entities"></app-pagination>
</app-page-footer>
