import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../../ri-loader-component/loader.module';
import {SharedModule} from '../../_shared/shared.module';
import {ToolbarModule} from '../../toolbar/toolbar.module';
import {SiteService} from './services/site.service';
import {SiteAssetsComponent} from './site-assets/site-assets.component';
import {SiteDetailsComponent} from './site-details/site-details.component';
import {SiteNavWrapperComponent} from './site-nav-wrapper/site-nav-wrapper.component';
import {SitePaperworkComponent} from './site-paperwork/site-paperwork.component';
import {SiteVisitsComponent} from './site-visits/site-visits.component';
import {SitesListComponent} from './sites-list/sites-list.component';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		RouterModule,
		LoaderModule,
		ToolbarModule,
	],
	declarations: [
		SitesListComponent,
		SiteDetailsComponent,
		SiteNavWrapperComponent,
		SitePaperworkComponent,
		SiteVisitsComponent,
		SiteAssetsComponent,
	],
	providers: [SiteService],
})
export class SitesModule {
}
