import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../../ri-loader-component/loader.module';
import {AuthenticationService} from '../authentication.service';
import {LoginComponent} from './login.component';


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		LoaderModule,
	],

	declarations: [
		LoginComponent,
	],
})
export class LoginModule {
}
