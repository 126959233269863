
import {switchMap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderComponent} from '../../../../ri-loader-component/loader.component';
import {proxyCrmEntity} from '../../../_shared/crm/proxy-crm-entity';
import {NewReportDialogComponent} from '../../../_shared/new-report-dialog/new-report-dialog.component';
import {AuthenticationService} from '../../../authentication.service';
import {VisitService} from '../services/visit.service';
import {Appointment} from "../../../../richardirvin-entities/type-aliases";
import {firstValueFrom} from "rxjs";
import {EntityList} from "../../_controllers/entity-list";
import {XmlListLayoutComponent} from "../../../_shared/xml-list-layout/xml-list-layout.component";
import {HttpClient} from "@angular/common/http";

@Component({
	selector:    'app-visit-nav-wrapper',
	templateUrl: './visit-nav-wrapper.component.html',
	styleUrls:   ['./visit-nav-wrapper.component.scss'],
})
export class VisitNavWrapperComponent implements OnInit {
	id!: string;
	visit!: Appointment;
	casenumber!: string;

	@ViewChild(LoaderComponent) protected loader!: LoaderComponent;
	@ViewChild(NewReportDialogComponent, { static: true }) newReportDialog!: NewReportDialogComponent;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private visitService: VisitService,
		protected toast: MatSnackBar,
		public auth: AuthenticationService,
	) {
	}

	public ngOnInit(): void {
		this.route.paramMap.pipe(
			switchMap((params: ParamMap) => {
				this.id = params.get('id')!;
				return this.visitService.getVisit(params.get('id')!);
			}))
			.subscribe((visit) => {
				this.visit         = proxyCrmEntity<Appointment>(visit);

				const ticketNumber = visit.attributes.find(a => a.key === 'ticketnumber');

				if (ticketNumber) {
					this.casenumber = ticketNumber.value as string;
				}
			});
	}

	public backButtonClick() {
		this.router.navigate(['../'], {relativeTo: this.route});
	}

	public openNewReportDialog() {
		this.newReportDialog.openDialog();
	}
	onActivate($event: any) {
		if ($event instanceof EntityList) {
			this.listLayout = $event.listLayout;
		} else {
			this.listLayout = undefined;
		}
		this.changeRef.detectChanges();
	}

	listLayout?: XmlListLayoutComponent;

	ngZone = inject(NgZone);
	changeRef = inject(ChangeDetectorRef);
	http = inject(HttpClient);
	async 	downloadBatch() {
		if (this.listLayout === undefined) {
			return;
		}
		const ids = this.listLayout.selectionModel.selected;
		let formData = new FormData();

		ids.forEach(id => formData.append('ids', id));


		let handle: FileSystemFileHandle;

		try {
			handle = await window.showSaveFilePicker({
				suggestedName: 'batch.zip',
				excludeAcceptAllOption: true,
				types: [
					{accept: {'application/zip': ['.zip']}, description: 'Zip Archive of PDF Files'},
					{accept: {'application/pdf': ['.pdf']}, description: 'Single PDF File'},
				]
			});
		}
		catch (e) {
			return;
		}

		if (handle == null) {
			return;
		}


		const blob = await firstValueFrom(this.http.post(`/api/batch-pdf/${handle.name}`, formData, {
			responseType: 'blob',
		}));

		const writable = await handle.createWritable({keepExistingData: false});

		await writable.write(blob);
		await writable.close();
	}
}
