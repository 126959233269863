import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss']
})
export class ToolbarSearchComponent implements OnInit, OnDestroy{
@ViewChild('searchInput', {static:true})
protected searchInput!: ElementRef<HTMLInputElement>

  @Output()
  public searchTerm:EventEmitter<string|null> = new EventEmitter<string|null>();

  readonly #subscriptionBag = new Set<Subscription>();




  ngOnInit(): void {
    const searchSubscription = fromEvent<InputEvent>(this.searchInput.nativeElement, 'input').pipe(
      debounceTime(500)
    ).subscribe({
      next: (event) => {
        const target = event.target as HTMLInputElement;
        const value = target.value.trim();
        this.searchTerm.emit(value === '' ? null : value);
      }
    });

    this.#subscriptionBag.add(searchSubscription);
  }

  ngOnDestroy(): void {
    this.#subscriptionBag.forEach(subscription => subscription.unsubscribe());
    this.#subscriptionBag.clear();
  }



}
