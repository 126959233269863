import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../directives/directives.module';
import {NewReportDialogComponent} from './new-report-dialog.component';
import {ReportService} from './services/report.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
	],
	providers: [ReportService],
	declarations: [NewReportDialogComponent],
	exports: [NewReportDialogComponent],
})
export class NewReportDialogModule {
}