import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmEntity} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {APIService} from './jobs.service';
import {firstValueFrom} from "rxjs";

@Injectable()
export class JobNoteService extends APIService {
	protected _api = '/api/notes';

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public get(guid: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<CrmEntity>(`${this._api}/${guid}`, {headers}));
	}

}
