import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	EventEmitter,
	Injectable,
	Injector,
} from '@angular/core';
import {CrmEntity} from '../../../../_shared/crm/entity';
import {VisitStatusComponent} from './visit-status/visit-status.component';

@Injectable()
export class VisitStatusService {

	private componentRef!: ComponentRef<VisitStatusComponent>;
	public dialogClosed: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private factoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
	) {
	}

	public show(visitEntity: CrmEntity) {
		if (!this.componentRef) {
			this.componentRef = this.factoryResolver
				.resolveComponentFactory(VisitStatusComponent)
				.create(this.injector);
		}

		this.componentRef.instance.openDialog(visitEntity);
		this.componentRef.instance.closed.subscribe(() => this.close());

		this.appRef.attachView(this.componentRef.hostView);

		const domElm: HTMLElement = (this.componentRef.hostView as EmbeddedViewRef<any>)
			.rootNodes[0] as HTMLElement;

		document.body.appendChild(domElm);

	}

	public close() {
		this.appRef.detachView(this.componentRef.hostView);
		this.dialogClosed.next();
		// this.componentRef.destroy();
	}

}
