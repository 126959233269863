<div class="modal-card">
    <form #frm="ngForm" (ngSubmit)="submit(frm)" novalidate>
        <header class="modal-card-head">
            <p class="modal-card-title">Change Password</p>
            <button (click)="submitted.emit()" aria-label="close" class="delete"></button>
        </header>

        <div class="modal-card-body">
            <div class="field">
                <label class="label" for="current">Current Password</label>
                <div class="control">
                    <input
                        #currentPassword=ngModel
                        [class.is-danger]="currentPassword.touched && currentPassword.invalid"
                        autocomplete="current-password"
                        class="input"
                        id="current"
                        name="currentPassword"
                        ngModel
                        required
                        type="password"
                    >
                </div>
            </div>

            <div class="field">
                <label class="label" for="new">New Password</label>
                <input
                    #newPassword=ngModel
                    [class.is-danger]="newPassword.touched && newPassword.invalid"
                    autocomplete="new-password"
                    class="input"
                    id="new"
                    name="newPassword"
                    ngModel
                    required
                    type="password"
                >
            </div>

            <div class="field">
                <label class="label" for="confirm">Confirm Password</label>
                <input
                    #confirmPassword=ngModel
                    [class.is-danger]="confirmPassword.touched && confirmPassword.invalid"
                    autocomplete="new-password"
                    class="input"
                    id="confirm"
                    name="confirmPassword"
                    ngModel
                    required
                    type="password"
                >
            </div>

            <div class="message is-danger" *ngIf="response">
                <div class="message-body">{{ response }}</div>
            </div>
        </div>

        <footer class="modal-card-foot">
            <div style="margin-left: auto">
                <button (click)="submitted.emit()" class="button" type="button">Cancel</button>
                <button [disabled]="frm.invalid" class="button is-success" type="submit">Update Password</button>
            </div>
        </footer>
    </form>
</div>

