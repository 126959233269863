import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {Job} from "../../../../richardirvin-entities/type-aliases";
import {firstValueFrom} from "rxjs";

type CreateJob = {
  contractId: string;
  siteId: string;
  priorityId: string;
  description: string | null;
  workOrder: string | null;
};

@Injectable()
export class JobService {

  api: string = '/api';

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
  ) {
  }

  public create(job: CreateJob): Promise<{ id: string }> {
    return firstValueFrom(this.http.post<{ id: string }>(`${this.api}/incidents`, job));
  }

  async uploadAttachment(job: string, attachments: File[]) {
    const formData = new FormData();
    for (const file of attachments)
      formData.append('Files', file, file.name);
    return firstValueFrom(this.http.post<void>(`${this.api}/incidents/${job}/notes`, formData));
  }
}
