import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {JobsService} from '../services/jobs.service';

@Component({
	selector: 'app-job-visits',
	templateUrl: './job-visits.component.html',
	styleUrls: ['./job-visits.component.scss'],
})
export class JobVisitsComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.JobVisits;

	public constructor(
		protected jobService: JobsService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntities(params: URLSearchParams) {
		return this.jobService.getJobVisits(this.id, params);
	}
}
