import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-toolbar-spacer',
	templateUrl: './toolbar-spacer.component.html',
	styleUrls: ['./toolbar-spacer.component.scss'],
})
export class ToolbarSpacerComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
