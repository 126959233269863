<dialog #newNoteDialog class="new-note">
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner">
        <h1>Add new note</h1>
        <form [formGroup]="newNoteForm" class="form" (submit)="createNote()">
            <div class="form-main">
                <div class="inputs">
                    <label class="expanded">Note
                        <textarea formControlName="note"></textarea>
                    </label>
                </div>
            </div>
            <div class="form-sidebar">
                <div class="inputs">
                    <label>Attach File
                        <input #attachment type="file" multiple>
                    </label>
                </div>
                <div class="controls">
                    <button class="success" [class.disabled]="!newNoteForm.valid">submit</button>
                </div>
            </div>
        </form>
    </div>
</dialog>
