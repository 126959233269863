<dialog #errorDialog class="error-handler" open>
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner">
        <h1>{{error.title}}</h1>
        <p>{{error.message}}</p>
        <p>{{error.caughtError.message}}</p>

        <form hidden action="mailto:support@intellicore.co.uk" method="get" enctype="application/x-www-form-urlencoded">
            <input type="hidden" name="subject"
                   value="Richard Irvin Client Portal Error: {{error.caughtError.message}}">
            <input type="hidden" name="body"
                   value="Please provide details on what you were doing below:\n\n\n\n\n\n\n\n\n\n{{error.caughtError.stack}}">
            <button>Send Email</button>
        </form>
        <code hidden class="stack-trace">{{error.caughtError.stack}}</code>
    </div>
</dialog>
