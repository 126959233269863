import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FilterDialogModule} from '../filter-dialog/filter-dialog.module';
import {PipesModule} from '../pipes/pipes.module';
import {XmlListLayoutComponent} from './xml-list-layout.component';

@NgModule({
	imports: [
		CommonModule,
		FilterDialogModule,
		PipesModule,
		FontAwesomeModule,
	],
	declarations: [XmlListLayoutComponent],
	exports: [XmlListLayoutComponent],
})
export class XmlListLayoutModule {
}
