import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ListView} from '../../interfaces/list-view';
import {CSVDownload} from './csvdownload';
import {Filter} from './filter';

@Component({
	selector: 'app-page-toolbar',
	templateUrl: './page-toolbar.component.html',
	styleUrls: ['./page-toolbar.component.scss'],
})

export class PageToolbarComponent implements OnChanges {

	@Input('title') title!: string;

	@Input('filter') filter!: Filter;

	@Input('csvDownload') csvDownload!: CSVDownload;

	@Input('newButton') newButton!: string;

	@Input('refresh') refresh!: false;

	@Output() newButtonClicked = new EventEmitter();

	@Output() filterUpdated = new EventEmitter();

	@Output() refreshClicked = new EventEmitter();

	selectedFilter!: ListView;

	constructor() {
	}

	ngOnChanges() {
		if (this.filter) {
			this.selectedFilter = this.filter.options[0];
		}
	}

	public filterChanged(): void {
		this.filterUpdated.emit(this.selectedFilter);
	}

	public addNewButtonClicked(): void {
		this.newButtonClicked.emit();
	}

	public refreshButtonClicked(): void {
		this.refreshClicked.emit();
	}

}
