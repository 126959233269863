import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-toolbar-link',
	templateUrl: './toolbar-link.component.html',
	styleUrls: ['./toolbar-link.component.scss'],
})
export class ToolbarLinkComponent implements OnInit {
	constructor() {
	}

	ngOnInit() {
	}

}
