import {Component, Input, OnChanges} from '@angular/core';
import {FormView} from '../../interfaces/form-view';
import {CrmEntity, PaginatedEntity} from '../crm/entity';
import {Cell} from './interfaces/cell';
import {Column} from './interfaces/column';
import {Row} from './interfaces/row';
import {Section} from './interfaces/section';
import {Tab} from './interfaces/tab';

@Component({
	selector: 'app-xml-page-layout',
	templateUrl: './xml-page-layout.component.html',
	styleUrls: ['./xml-page-layout.component.scss'],
})
export class XmlPageLayoutComponent implements OnChanges {
	@Input() formXml!: string;
	@Input() entity!: CrmEntity;
	@Input() view!: FormView;

	tabs: Tab[] = [];

	public ngOnChanges(): void {
		if (this.view) {
			this.generateView();
		}
	}

	public getEntityValue(controlName: string, entity: CrmEntity = this.entity): string {
		if (this.entity === undefined) {
			return '';
		}

		if (this.entity.hasOwnProperty(controlName)) {
			return Reflect.get(entity, controlName);
		}

		const formatted = entity.formattedValues.find(a => a.key === controlName);
		const attr = entity.attributes.find(a => a.key === controlName);

		if (formatted) {
			return formatted.value;
		}

		if (attr) {
			if (typeof attr.value === 'string') {
				return attr.value;
			}
			else {
				// it's a thingy
				const ent = attr.value as PaginatedEntity;
				if (ent.entityName === 'activityparty') {
					return this.getEntityValue('partyid', ent.entities[0]);
				}
				else {
					return '';
				}
			}
		}

		// if (attr.value.type === FilterTypes.Date) {
		// 	if (attr.value.includes(':')) {
		// 		return moment(attr.value, 'M/D/YYYY H:mm A').format('DD/MM/YYYY H:mm A');
		//
		// 	} else {
		// 		return moment(attr.value, 'MM/DD/YYYY').format('DD/MM/YYYY');
		// 	}
		// }

		console.warn('Could not find: ', controlName);
		return null!;
	}

	parseLabels(labelsNode: Element): string[] {
		const labels = [];

		for (const child of Array.from(labelsNode.childNodes)) {
			switch (child.nodeName) {
				case 'label': {
					labels.push(this.parseLabel(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}

		return labels;
	}

	parseLabel(label: Element): string {
		return label.attributes.getNamedItem('description')!.value;
	}

	parseColumns(columnsNode: Element): Column[] {
		const columns = <Column[]>[];
		for (const child of Array.from(columnsNode.childNodes)) {
			switch (child.nodeName) {
				case 'column': {
					columns.push(this.parseColumn(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}
		return columns;
	}

	parseColumn(columnNode: Element): Column {
		const column = <Column> {sections: [], width: ''};
		column.width = columnNode.attributes.getNamedItem('width')?.value || '100%';

		for (const child of Array.from(columnNode.childNodes)) {
			switch (child.nodeName) {
				case 'sections': {
					column.sections = (this.parseSections(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}
		return column;
	}

	parseSections(sectionsNode: Element): Section[] {
		const sections = <Section[]> [];

		for (const child of Array.from(sectionsNode.childNodes)) {
			switch (child.nodeName) {
				case 'section': {
					sections.push(this.parseSection(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}
		return sections;
	}

	parseSection(sectionNode: Element): Section {
		const section = <Section> {
      label: '',
			rows: [],
			showLabel: true,
		};

		if (sectionNode.attributes.getNamedItem('showlabel')?.value === 'false') {
			section.showLabel = false;
		}

		for (const child of Array.from(sectionNode.childNodes)) {
			switch (child.nodeName) {
				case 'labels': {
					section.label = this.parseLabels(child as Element)[0] || '';
					break;
				}
				case 'rows': {
					section.rows = this.parseRows(child as Element);
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}
		return section;
	}

	parseRows(rowsNode: Element): Row[] {
		const rows = <Row[]>[];

		for (const child of Array.from(rowsNode.childNodes)) {
			switch (child.nodeName) {
				case 'row': {
					rows.push(this.parseRow(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}
		return rows;
	}

	parseRow(rowNode: Element): Row {
		const row = <Row> {};

		for (const child of Array.from(rowNode.childNodes)) {
			switch (child.nodeName) {
				case 'cell': {
					row.cell = this.parseCell(child as Element);
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}

		return row;
	}

	parseCell(cellNode: Element): Cell {
		const cell = <Cell> {};

		for (const child of Array.from(cellNode.childNodes)) {
			switch (child.nodeName) {
				case 'labels': {
					cell.label = this.parseLabels(child as Element)[0] || '';
					break;
				}
				case 'control': {
					cell.control_id = this.parseControlId(child as Element);
					break;
				}
				default:
					console.warn('String unhandled', child as Element);
			}
		}

		return cell;
	}

	parseControlId(controlNode: Element): string {
		return controlNode.attributes.getNamedItem('id')?.value ?? '';
	}

	private generateView() {
		const parser = new DOMParser();
		const doc = parser.parseFromString(this.view.formXml, 'application/xml');

		const form = doc.querySelector('form') as Element;

		for (const child of Array.from(form.childNodes)) {
			switch (child.nodeName) {
				case 'tabs': {
					console.log('TABS', child);
					this.tabs = this.parseTabs(child as Element);
					break;
				}
				// default: console.warn('String unhandled', child)
			}
		}
	}

	private parseTabs(tabsNode: Element): Tab[] {
		const tabs = <Tab[]>[];

		console.log(tabsNode);

		for (const child of Array.from(tabsNode.childNodes)) {
			switch (child.nodeName) {
				case 'tab': {
					tabs.push(this.parseTab(child as Element));
					break;
				}
				default:
					console.warn('String unhandled', child);
			}
		}
		return tabs;
	}

	private parseTab(tabNode: Element): Tab {
		const tab: Tab = {
			label: 'test',
			showLabel: true,
			columns: [],
			isVisible: true,
		};


		const visible = tabNode.attributes.getNamedItem('visible');
		if (visible) {
			tab.isVisible = visible.value !== 'false';
		}

		const showLabel = tabNode.attributes.getNamedItem('showlabel');
		if (showLabel) {
			tab.showLabel = showLabel.value !== 'false';
		}

		for (const child of Array.from(tabNode.childNodes)) {
			switch (child.nodeName) {
				case 'labels': {
					tab.label = this.parseLabels(child as Element)[0] || '';
					break;
				}
				case 'columns': {
					tab.columns = this.parseColumns(child as Element);
					break;
				}
				default:
					console.warn('String unhandled', child);
			}
		}

		return tab;
	}
}
