import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {proxyCrmEntity} from '../../../_shared/crm/proxy-crm-entity';
import {JobNoteService} from '../services/job-note.service';

export interface Note {
	annotationid: string;
	createdby: { name: string };
	subject: string;
	createdon: Date;
	filename: string;
	notetext: string;
}

@Component({
	selector:    'app-job-note',
	templateUrl: './job-note.component.html',
	styleUrls:   ['./job-note.component.scss'],
})
export class JobNoteComponent implements OnInit {
	public note: Note = {
		annotationid: null!,
		createdby:    {name: null!},
		subject:      null!,
		createdon:    null!,
		filename:     null!,
		notetext:     null!,
	};

	public constructor(
		private noteSvc: JobNoteService,
		protected activatedRoute: ActivatedRoute,
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.activatedRoute.queryParamMap.subscribe(async (params: ParamMap) => {
			this.note.annotationid = params.get('id')!;

			if (this.note.annotationid && this.note.annotationid !== '') {
				const note = await this.noteSvc.get(this.note.annotationid);
				this.note = proxyCrmEntity<Note>(note);
			}
		});
	}

	public async goToNote(): Promise<void> {
		//todo: implement
		throw new Error('Method not implemented.');
	}

	public async openAttachment(): Promise<void> {
		//todo: implement
		throw new Error('Method not implemented.');
	}
}
