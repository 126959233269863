import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CsvExportService} from './csv-export.service';
import {DownloadPanelComponent} from './download-panel/download-panel.component';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		DownloadPanelComponent,
	],
	declarations: [
		DownloadPanelComponent,
	],
	providers: [
		CsvExportService,
	],
})
export class CsvExportModule {
}
