import {ChangeDetectorRef, Component, inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {LoaderComponent} from '../../../../ri-loader-component/loader.component';
import {proxyCrmEntity} from '../../../_shared/crm/proxy-crm-entity';
import {SiteService} from '../services/site.service';
import {Site} from "../../../../richardirvin-entities/type-aliases";
import {EntityList} from "../../_controllers/entity-list";
import {XmlListLayoutComponent} from "../../../_shared/xml-list-layout/xml-list-layout.component";
import {firstValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
	selector:    'app-site-nav-wrapper',
	templateUrl: './site-nav-wrapper.component.html',
	styleUrls:   ['./site-nav-wrapper.component.scss'],
})
export class SiteNavWrapperComponent implements OnInit {
	id!: string;
	site!: Site;
	casenumber!: string;
	@ViewChild(LoaderComponent) protected loader!: LoaderComponent;
	showDownload: any = false;

	http = inject(HttpClient);
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private siteService: SiteService,
	) {
	}

	public ngOnInit(): void {
		this.route.paramMap.pipe(
			switchMap((params: ParamMap) => {
				this.id = params.get('id')!;
				return this.siteService.getSite(params.get('id')!);
			}),
		).subscribe((site) => {
			this.site = proxyCrmEntity<Site>(site);

			const caseNumber = site.attributes.find(a => a.key === 'ri_name');
			if (caseNumber) {
				this.casenumber = caseNumber.value as string;
			} else {
				this.casenumber = 'Unknown Case Number';
			}
		});
	}

	public backButtonClick() {
		this.router.navigate(['../'], {relativeTo: this.route});
	}

	listLayout?: XmlListLayoutComponent;

	ngZone = inject(NgZone);
	changeRef = inject(ChangeDetectorRef);
	
	onActivate($event: any) {
		if ($event instanceof EntityList) {
			this.listLayout = $event.listLayout;
		} else {
			this.listLayout = undefined;
		}
		this.changeRef.detectChanges();
	}

async 	downloadBatch() {
		if (this.listLayout === undefined) {
			return;
		}
		const ids = this.listLayout.selectionModel.selected;
		let formData = new FormData();

		ids.forEach(id => formData.append('ids', id));


		let handle: any;

		try {
			handle = await window.showSaveFilePicker({
				suggestedName: 'batch.zip',
				excludeAcceptAllOption: true,
				types: [
					{accept: {'application/zip': ['.zip']}, description: 'Zip Archive of PDF Files'},
					{accept: {'application/pdf': ['.pdf']}, description: 'Single PDF File'},
				]
			});
		}
		catch (e) {
			return;
		}

		if (handle == null) {
			return;
		}


		const blob = await firstValueFrom(this.http.post(`/api/batch-pdf/${handle.name}`, formData, {
			responseType: 'blob',
		}));

		const writable = await handle.createWritable({keepExistingData: false});

		await writable.write(blob);
		await writable.close();
	}
}
