import {Directive, ElementRef, HostListener, inject} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  selector: '[appFile]',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useClass: FileDirective, multi: true}
  ]
})
export class FileDirective implements ControlValueAccessor {
  readonly #elementRef = inject(ElementRef<HTMLInputElement>);
  #onChange: any = () => {
  };
  #onTouched: any = () => {
  };

  @HostListener('change', ['$event.target.files'])
  protected onInputChange(files: FileList) {
    const fileArray = Array.from(files);
    this.#onTouched();
    this.#onChange(fileArray);
    console.log('onChange', fileArray);

  }

  writeValue(files: any): void {
    console.log('write', files);
  }

  registerOnChange(fn: any): void {
    this.#onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.#onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.#elementRef.nativeElement.disabled = isDisabled;
  }
}
