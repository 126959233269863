import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ChangePasswordComponent} from '../home/change-password/change-password.component';
import {ProtectedModule} from '../protected.module';
import {NavbarWrapperComponent} from './navbar-wrapper.component';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
	],
	declarations: [NavbarWrapperComponent, ChangePasswordComponent],
	exports: [NavbarWrapperComponent],
})
export class NavbarWrapperModule {
}
