<ng-container *ngIf="filter">
    <div class="filter-by">Filter by {{ filter.name }}</div>
    <div class="custom-select" (click)="selectOpen = !selectOpen">
        <div class="select-icon" [class.open]="selectOpen"></div>
        {{ selectedView ? selectedView.name : 'Select Filter' }}
        <div class="custom-dropdown" [class.open]="selectOpen">
            <div class="dropdown-btn" *ngFor="let option of filter.options"
                 (click)="handleOptionClick(option)">{{ option.name }}
            </div>
        </div>
    </div>
</ng-container>