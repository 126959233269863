<app-download-panel></app-download-panel>

<router-outlet></router-outlet>
<swal
    #errorSwal
    title="Error Title"
    text="Error Text"
    icon="error"
>
</swal>
