import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AxisLabelComponent} from '@swimlane/ngx-charts';

@Component({
  selector: 'app-charts-axis-label,g[app-charts-axis-label]',
  templateUrl: './charts-axis-label.component.html',
  styleUrls: ['./charts-axis-label.component.scss'],
})
export class ChartsAxisLabelComponent extends AxisLabelComponent {

}
