import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PaginationComponent} from './pagination.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
	],
	declarations: [PaginationComponent],
	exports: [PaginationComponent],
})
export class PaginationModule {
}
