import {HttpClient} from '@angular/common/http';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {AssetService} from '../services/asset.service';

@Component({
	selector: 'app-asset-details',
	templateUrl: './asset-details.component.html',
	styleUrls: ['./asset-details.component.scss'],
})
export class AssetDetailsComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.Assets;

	public constructor(
		protected entitySvc: AssetService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
		protected http: HttpClient,
	) {
		super();
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		const id = this.activatedRoute.snapshot.paramMap.get('id') ?? this.activatedRoute.snapshot.paramMap.get('asset_id')  ;
		return this.entitySvc.getChildAssets(id!, params);
	}
}
