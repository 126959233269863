import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-toolbar-title',
	templateUrl: './toolbar-title.component.html',
	styleUrls: ['./toolbar-title.component.scss'],
})
export class ToolbarTitleComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
