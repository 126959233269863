import {Operators} from '../_shared/filter-dialog/operators.enum';
import {FetchXml} from './index';

export type LinkedEntityReference ={ name: string; alias: string; attributes: ReadonlyArray<string>; };

export class SavedQuery extends FetchXml {
	public [Symbol.toStringTag] = 'SavedQuery';

	private savedQuery!: Element;
	private fetch: Element;

	public constructor(document: Document | string) {
		super(document);
		this.fetch = this.document.querySelector('fetch')!;
	}

	get entityName(): string {
		return this.entity.getAttribute('name')!;
	}

	get entityNames(): ReadonlyArray<string> {
		return Array.from(
			this.fetch.querySelectorAll('entity,link-entity'),
			e => e.getAttribute('name')!,
		);
	}

	get linkedEntities():  ReadonlyArray<LinkedEntityReference> {
		return Array.from(
			this.fetch.querySelectorAll('link-entity'),
			e => ({
				name: e.getAttribute('name')!,
				alias: e.getAttribute('alias')!,
				attributes: Array.from(
					e.querySelectorAll('attribute'),
					e => e.getAttribute('name')!,
				),
			}),
		);
	}

	get name(): string {
		return ' Unknown ';
	}

	get count(): number | null {
		if (this.fetch.hasAttribute('count')) {
			return parseInt(this.fetch.getAttribute('count')!, 10);
		}
		else {
			return null;
		}
	}

	set count(val: number | null) {
		this.fetch.setAttribute('count', val!.toString());
	}

	get page(): number {
		return parseInt(this.fetch.getAttribute('page')!, 10);
	}

	set page(val: number) {
		this.fetch.setAttribute('page', val.toString());
	}

	get returnTotalRecordCount(): boolean {
		return this.fetch.getAttribute('returntotalrecordcount') === 'true';
	}

	set returnTotalRecordCount(val: boolean) {
		this.fetch.setAttribute('returntotalrecordcount', val === true ? 'true' : 'false');
	}

	get orderColumn(): string | null {
		const orderNode = this.fetch.querySelector('order');
		if (orderNode) {
			return orderNode.getAttribute('attribute') || null;
		}
		else {
			return null;
		}
	}

	get orderDirection(): 'asc' | 'desc' {
		const orderNode = this.fetch.querySelector('order');
		if (orderNode) {
			const idDescending = orderNode.getAttribute('descending');

			if (idDescending === 'false') return 'asc';
			if (idDescending === 'true') return 'desc';
			return null!;
		}
		else {
			return null!;
		}
	}

	protected get entity(): Element {
		return this.fetch.querySelector('entity')!;
	}

	public setOrder(column: string, direction: 'asc' | 'desc') {
		let orderNode = this.fetch.querySelector('order');

		if (!orderNode) {
			orderNode = this.document.createElement('order');
			this.entity.appendChild(orderNode);
		}

		orderNode.setAttribute('attribute', column);
		orderNode.setAttribute('descending', direction === 'desc' ? 'true' : 'false');
	}

	public clearOrder(): void {
		const orderNode = this.fetch.querySelector('order');
		if (orderNode) {
			orderNode.remove();
		}
	}

	public setFilter(column: string, operator: Operators, value: any): void {
		this.clearFilter();

		if (operator === Operators.Contains) {
			// Add wildcards
			value = '%' + value + '%';
		}

		const filterNode = this.document.createElement('filter');
		filterNode.setAttribute('type', 'and');

		const conditionNode = this.document.createElement('condition');
		conditionNode.setAttribute('attribute', column);
		conditionNode.setAttribute('operator', `${operator}`);
		conditionNode.setAttribute('value', value);

		filterNode.appendChild(conditionNode);

		this.entity.appendChild(filterNode);
	}

	public getFilter() {
		const filterNode = this.fetch.querySelector('filter');
		if (filterNode) {
			console.log('filternode', filterNode);
		}
	}

	public clearFilter(): void {
		const filterNode = this.fetch.querySelector('filter');
		if (filterNode) {
			filterNode.remove();
		}
	}
}





