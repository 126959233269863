<header>
    <div id="logo" class="logo-area">
        <div class="logo">
            <img src="/assets/logo.png">
            <ng-container *ngIf="selectedContract">
                <img [src]="'/api/accounts/' + selectedContract.accountId+ '/image'"
                     alt="Account Logo"
                     style="height:calc(100% - 20px);margin-top:10px;">
            </ng-container>
        </div>
    </div>

    <nav class="nav-wrapper">
        <div class="welcome">
            <div class="welcome-msg">welcome {{ welcomeMsg }}</div>

            <div class="contract-selector" *ngIf="user">
                <label for="contract">Select Contract</label>
                <select id="contract" [compareWith]="compareContracts" [(ngModel)]="selectedContract"
                        (change)="auth.changeCurrentContract(selectedContract)">
                    <option *ngFor="let contract of $contracts | async" [ngValue]="contract">
                        {{contract.name}}
                    </option>
                </select>
            </div>
            <div (click)="showPassword()" class="password">
                password
                <div class="icon"></div>
            </div>
            <a href="/userguide" target="guide" class="userguide">
                user-guide
                <div class="icon"></div>
            </a>
            <div class="logout" (click)="submitLogout()">
                log out
                <div class="icon"></div>
            </div>
        </div>

        <nav class="main-nav">
            <div class="main-nav-item" routerLink="/" routerLinkActive="selected" [routerLinkActiveOptions]="{exact:
true}">
                home
            </div>

            <ng-container
                    *ngTemplateOutlet="selectedContract && selectedContract.isSubContractor ? subContractorNavigation: clientNavigation"></ng-container>

            <ng-template #clientNavigation>
                <div class="main-nav-item" routerLink="/jobs" routerLinkActive="selected">
                    jobs
                    <div class="icon"></div>
                </div>
                <div class="main-nav-item" routerLink="/sites" routerLinkActive="selected">
                    sites
                    <div class="icon"></div>
                </div>
                <div class="main-nav-item" routerLink="/visits" routerLinkActive="selected">
                    visits
                    <div class="icon"></div>
                </div>
                <div class="main-nav-item" routerLink="/assets" routerLinkActive="selected">
                    assets
                    <div class="icon"></div>
                </div>
                <div class="main-nav-item" routerLink="/paperwork" routerLinkActive="selected">
                    documentation
                    <div class="icon"></div>
                </div>
            </ng-template>

            <ng-template #subContractorNavigation>

                <div class="main-nav-item" routerLink="/visits" routerLinkActive="selected">
                    visits
                    <div class="icon"></div>
                </div>

                <div class="main-nav-item" routerLink="/paperwork" routerLinkActive="selected">
                    documentation
                    <div class="icon"></div>
                </div>
            </ng-template>
            <ng-container *ngIf="selectedContract.ri_showppmschedule">
            <div class="main-nav-item" routerLink="/ppm" routerLinkActive="selected">ppm <div class="icon"></div></div>
            </ng-container>
        </nav>
    </nav>
</header>

<div class="page">
    <router-outlet></router-outlet>
</div>

<div *ngIf="showLogin" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
        <app-change-password (submitted)="showLogin = false"></app-change-password>
    </div>
</div>
