import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {Contract} from '../../../interfaces/contract';
import {CsvExportService} from '../../../modules/csv-export/csv-export.service';
import {Download} from '../../../modules/csv-export/download';
import {EntityList} from '../../_controllers/entity-list';
import {SiteService} from '../services/site.service';

@Component({
	selector:    'app-sites-list',
	templateUrl: './sites-list.component.html',
	styleUrls:   ['./sites-list.component.scss'],
})
export class SitesListComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.Sites;

	public constructor(
		protected siteSvc: SiteService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
		protected http: HttpClient,
		public csvExportService: CsvExportService,
	) {
		super();
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.siteSvc.getSites(params);
	}

	public async downloadCSV() {
		const contract: Contract = this.auth.currentContract.getValue();
		const headers = new HttpHeaders().append('Authorization', this.auth.getAuthorization());
		const params = new HttpParams({fromString: `${this.generateQueryParams()}`}).set('contractId', contract.id);

		const csvExport = await this.http.get<Download>(`/api/sites/csv`, {
			headers,
			params,
		}).toPromise();

		this.csvExportService.addDownload(csvExport!);
	}
}
