<ri-loader></ri-loader>

<app-page-toolbar>
    <app-toolbar-filter
        (selectedViewChanged)="changeView($event)"
        [filter]="filter"
        [selectedView]="selectedView"
    ></app-toolbar-filter>

    <app-toolbar-search (searchTerm)="search($event)" />

    <app-toolbar-spacer></app-toolbar-spacer>

    <app-toolbar-button
            *ngIf="canCreateJob$ | async"
        (click)="openNewJobDialog()"
        icon="/assets/icons/chevron_down_white.svg"
    >New Job
    </app-toolbar-button>

    <app-toolbar-csv-download
        (click)="downloadCSV()"
    >Download Workbook
    </app-toolbar-csv-download>

    <app-toolbar-icon (click)="handleRefreshClick()" src="assets/icons/refresh.svg"></app-toolbar-icon>
</app-page-toolbar>

<app-xml-list-layout
    #listScroll
    (entityClicked)="goToEntity($event)"
    (listViewChange)="handleViewChange()"
    [(listView)]="selectedView"
    [entityList]="entities">
</app-xml-list-layout>

<app-page-footer>
  <app-powered-by/>
  <app-pagination [currentPage]="currentPage" [maxRecords]="maxRecords" [records]="entities"></app-pagination>
</app-page-footer>

<app-new-job-dialog (saving)="handleNewJobSaving($event)"></app-new-job-dialog>
