import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../../ri-loader-component/loader.module';
import {SharedModule} from '../../_shared/shared.module';
import {ToolbarModule} from '../../toolbar/toolbar.module';
import {JobDetailsComponent} from './job-details/job-details.component';
import {JobNavWrapperComponent} from './job-nav-wrapper/job-nav-wrapper.component';
import {JobNoteComponent} from './job-note/job-note.component';
import {JobNotesComponent} from './job-notes/job-notes.component';
import {JobPaperworkComponent} from './job-paperwork/job-paperwork.component';
import {JobVisitsComponent} from './job-visits/job-visits.component';
import {JobsListComponent} from './jobs-list/jobs-list.component';
import {JobNoteService} from './services/job-note.service';
import {JobsService} from './services/jobs.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		RouterModule,
		LoaderModule,
		ToolbarModule,
	],
	declarations: [
		JobDetailsComponent,
		JobsListComponent,
		JobNavWrapperComponent,
		JobVisitsComponent,
		JobPaperworkComponent,
		JobNotesComponent,
		JobNoteComponent,
	],
	providers: [JobsService, JobNoteService],
})
export class JobsModule {
}
