import { OnInit, ViewChild, Directive } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderComponent} from '../../../ri-loader-component/loader.component';
import CaughtError from '../../_shared/caught-error';
import {CrmEntity} from '../../_shared/crm/entity';
import {FormViewTypes} from '../../_shared/form-view-types.enum';
import {FormViewService} from '../../_shared/services/form-view.service';
import {XmlPageLayoutComponent} from '../../_shared/xml-page-layout/xml-page-layout.component';
import {AuthenticationService} from '../../authentication.service';
import {FormView} from '../../interfaces/form-view';

@Directive()
export abstract class EntityForm implements OnInit {
	@ViewChild(LoaderComponent, { static: true }) loader!: LoaderComponent;
	@ViewChild(XmlPageLayoutComponent, { static: true }) listLayout!: XmlPageLayoutComponent;

	public abstract formViewType: FormViewTypes;

	public id!: string;
	public entity!: CrmEntity;

	public formViews: FormView[] = [];
	public selectedView!: FormView;

	// Imported Angular things
	protected abstract formViewService: FormViewService;
	protected abstract router: Router;
	protected abstract activatedRoute: ActivatedRoute;
	protected toast!: MatSnackBar;
	protected auth!: AuthenticationService;

	public async ngOnInit(): Promise<void> {
		this.loader.show();
		try {
			this.formViews    = await this.formViewService.getViews(this.formViewType);
			if (this.formViews.length < 1) {
				throw (CaughtError.Warning('No views available', 'Please contact your system administrator.', new Error()));
			}
			this.selectedView = this.formViews[0];
		} catch (ex) {
			throw (CaughtError.Warning('No views available', 'Please contact your system administrator.', new Error()));
			// await this.errorToast(ex);
		}

		this.activatedRoute.parent!.paramMap.subscribe(async (params: ParamMap) => {
			this.id = params.get('id')!;
			await this.populateView();
		});

	}

	public async populateView(): Promise<void> {
		this.loader.show();
		try {
			this.entity = await this.getEntity(this.id);
		} catch (ex) {
			// TODO: Handle no views error
			this.errorToast(ex);
			console.error(ex);
			throw ex;
		}
		finally {
			this.loader.dismiss();
		}
	}

	public async changeView(selectedView: FormView): Promise<void> {
		this.selectedView = selectedView;
		await this.populateView();
	}

	public async handleRefreshClick(): Promise<void> {
		await this.populateView();
	}

	public async errorToast(error: any) {
		if (error.status === 403) {
			console.log('forbidden');
			await this.auth.logout();
			this.router.navigate(['/login'], {queryParams: {returnUrl: this.activatedRoute.snapshot.url}});
			this.toast.open('Please login to continue', undefined, {duration:2000});
			return;
		}

		if (error.status === 500) {
			console.log('internal error');
			this.toast.open('Internal server error', undefined, {duration:2000});
			return;
		}

		const message = error.message ? error.message : error.toString();
		this.toast.open(message, undefined, {duration: 2000});
	}

	protected abstract getEntity(guid: string): Promise<CrmEntity>;
}
