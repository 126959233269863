import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmEntity, Entities} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {APIService} from '../../jobs/services/jobs.service';
import {firstValueFrom} from "rxjs";

@Injectable()
export class VisitService extends APIService {
	protected _api = `/api/visits`;

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public getVisits(params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/`, params);
	}

	public getVisit(id: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<CrmEntity>(this._api+`/${id}`, {headers}));
	}

	public getVisitPaperwork(id: string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/${id}/paperwork`, params);
	}

	public getVisitJob(id: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<CrmEntity>(this._api+`/${id}/incident`, {headers}));
	}

	public getVisitSite(id: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<CrmEntity>(this._api+`/${id}/site`, {headers}));
	}

	public updateVisitStatus(id: string, values: { status: number, description: string }) {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		Object.assign(values, {contractId: this.auth.currentContract.getValue().id});

		return firstValueFrom(this.http.post(this._api + `/${id}`, values, {headers}));
	}
}
