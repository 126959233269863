import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-toolbar-icon',
	templateUrl: './toolbar-icon.component.html',
	styleUrls: ['./toolbar-icon.component.scss'],
})
export class ToolbarIconComponent implements OnInit {

	@Input('src') source: string = '';

	constructor() {
	}

	ngOnInit() {
	}

}
