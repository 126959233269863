import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {proxyCrmEntity} from '../../../_shared/crm/proxy-crm-entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {NoteService} from '../../../_shared/new-note-dialog/services/note.service';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {JobsService} from '../services/jobs.service';

@Component({
	selector:    'app-job-notes',
	templateUrl: './job-notes.component.html',
	styleUrls:   ['./job-notes.component.scss'],
})
export class JobNotesComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.JobNotes;

	constructor(
		protected jobsSvc: JobsService,
		protected noteSvc: NoteService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	public override async ngOnInit(): Promise<void> {
		await super.ngOnInit();

		this.listLayout.addColumn(
			'attachment',
			'Attachment',
			100,
			(entity) => {
				const annotation = proxyCrmEntity<{ filename: string }>(entity);
				if (annotation.filename) {
					return 'Download Attachment';
				}
				else {
					return null;
				}
			},
			(entity) => {
				const annotation = proxyCrmEntity<{ filename: string }>(entity);
				if (annotation.filename) {
					this.downloadAttachment(entity);
				}
			},
		);
	}

	public downloadAttachment(entity: CrmEntity): Promise<void> {
		return this.noteSvc.downloadNote(entity.id);
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.jobsSvc.getJobNotes(this.id, params);
	}

	protected async fileChange(event: Event): Promise<void> {
		//todo: implement

		const fileList: FileList = (event.target as HTMLInputElement).files!;
		const file               = fileList[0];
		const reader             = new FileReader();
		reader.onloadend         = function (e) {
			// you can perform an action with readed data here
			console.log(reader.result);
		};

		reader.readAsText(file);
	}
}
