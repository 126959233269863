import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, first, map, switchMapTo} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service';
import {ContractService} from '../contract.service';

@Injectable({
	providedIn: 'root',
})
export class PPMGuardGuard implements CanActivate {

	constructor(
		public contractService: ContractService,
		public auth: AuthenticationService,
	) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.contractService.getContracts().pipe(
			switchMapTo(this.auth.currentContract),
			filter(c => c !== null),
			map(c => c.ri_showppmschedule),
			first(),
		);
	}

}
