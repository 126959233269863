import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {Subscription} from 'rxjs';
import CaughtError from './_shared/caught-error';
import {ErrorHandlerService} from './_shared/error-dialog/services/error-handler.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public title = 'app';
	public errorHandler!: Subscription;
	public errors = new Set();

	@ViewChild('errorSwal', { static: true }) private errorSwal!: SwalComponent;

	public constructor(
		private errorService: ErrorHandlerService,
	) {

	}

	public async ngOnInit(): Promise<void> {
		this.errorService.errors.subscribe((error: Error) => {
			const err = this.parseError(error);
			this.errorSwal.title = err.title;
			this.errorSwal.text = err.message;
			this.errorSwal.icon = err.status;
			this.errorSwal.fire();
		});
	}

	public async ngOnDestroy(): Promise<void> {
		this.errorHandler.unsubscribe();
	}

	public parseError(err: Error) {
		if (err instanceof CaughtError) return err;
		else return new CaughtError('Oops, something went wrong!', 'There was an error while displaying this web page.', err);
	}
}
