
import {switchMap, map, combineLatest, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';



import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from '../../authentication.service';
import {CrmEntity} from '../crm/entity';
import {GridColumn} from '../xml-list-layout/xml-list-layout.component';
import {FilterTypes} from './filter-types.enum';
import {ColumnFilter} from './interfaces/column-filter';
import {OperatorLabels, Operators} from './operators.enum';

@Component({
	selector: 'app-filter-dialog',
	templateUrl: './filter-dialog.component.html',
	styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit, DoCheck {
	@ViewChild('dialog', { static: true }) public dialog!: ElementRef;

	@Input() public filterBy!: ColumnFilter;
	@Input() public lookupOptions: CrmEntity[] = <CrmEntity[]>[];

	@Output() public filterByChange: EventEmitter<ColumnFilter> = new EventEmitter<ColumnFilter>();
	@Output() public opened: EventEmitter<void> = new EventEmitter<void>();
	@Output() public closed: EventEmitter<void> = new EventEmitter<void>();
	public filterTypes = FilterTypes;
	public operatorTypes = Operators;
	public entities!: Observable<any[]>;
	public entityQuery = new BehaviorSubject('');
	protected operators: Operators[] = <Operators[]>[];
	protected operatorLabels = OperatorLabels;

	constructor(
		public http: HttpClient,
		public auth: AuthenticationService,
	) {
	}

	public ngOnInit(): void {
		this.entities = this.entityQuery.pipe(
			debounceTime(250),
			distinctUntilChanged(),
			combineLatest(this.auth.currentContract),
			map(([query, contract]) => new HttpParams().append('query', query).append('contractId', contract.id)),
			switchMap(params => this.http.get<any[]>(`/api/filter/resource`, {params})),);
	}

	public ngDoCheck(): void {
		// Clear operators
		this.operators.length = 0;

		if (this.filterBy) {
			switch (this.filterBy.column.type) {
				case FilterTypes.Number: {
					this.operators.push(Operators.Equals);
					this.operators.push(Operators.NotEqual);
					this.operators.push(Operators.GreaterThan);
					this.operators.push(Operators.LessThan);
					this.operators.push(Operators.GreaterEqual);
					this.operators.push(Operators.LessEqual);
					this.operators.push(Operators.Between);
					this.operators.push(Operators.NotBetween);
					this.operators.push(Operators.Null);
					this.operators.push(Operators.NotNull);
					this.operators.push(Operators.Under);
					this.operators.push(Operators.NotUnder);
					this.operators.push(Operators.UnderOrEqual);
					this.operators.push(Operators.Above);
					this.operators.push(Operators.AboveOrEqual);
					break;
				}

				case FilterTypes.Date: {
					this.operators.push(Operators.Between);
					this.operators.push(Operators.NotBetween);
					this.operators.push(Operators.Null);
					this.operators.push(Operators.NotNull);
					this.operators.push(Operators.Yesterday);
					this.operators.push(Operators.Today);
					this.operators.push(Operators.Tomorrow);
					this.operators.push(Operators.Last7Days);
					this.operators.push(Operators.Next7Days);
					this.operators.push(Operators.LastWeek);
					this.operators.push(Operators.ThisWeek);
					this.operators.push(Operators.NextWeek);
					this.operators.push(Operators.LastMonth);
					this.operators.push(Operators.ThisMonth);
					this.operators.push(Operators.NextMonth);
					this.operators.push(Operators.On);
					this.operators.push(Operators.NotOn);
					this.operators.push(Operators.OnOrBefore);
					this.operators.push(Operators.OnOrAfter);
					this.operators.push(Operators.LastYear);
					this.operators.push(Operators.ThisYear);
					this.operators.push(Operators.NextYear);
					this.operators.push(Operators.LastXHours);
					this.operators.push(Operators.NextXHours);
					this.operators.push(Operators.LastXDays);
					this.operators.push(Operators.NextXDays);
					this.operators.push(Operators.LastXWeeks);
					this.operators.push(Operators.NextXWeeks);
					this.operators.push(Operators.LastXMonths);
					this.operators.push(Operators.NextXMonths);
					this.operators.push(Operators.LastXYears);
					this.operators.push(Operators.NextXYears);
					this.operators.push(Operators.OlderThanXMonths);
					this.operators.push(Operators.ThisFiscalYear);
					this.operators.push(Operators.ThisFiscalPeriod);
					this.operators.push(Operators.NextFiscalYear);
					this.operators.push(Operators.NextFiscalPeriod);
					this.operators.push(Operators.LastFiscalYear);
					this.operators.push(Operators.LastFiscalPeriod);
					this.operators.push(Operators.LastXFiscalYears);
					this.operators.push(Operators.LastXFiscalPeriods);
					this.operators.push(Operators.NextXFiscalYears);
					this.operators.push(Operators.NextXFiscalPeriods);
					this.operators.push(Operators.InFiscalYear);
					this.operators.push(Operators.InFiscalPeriod);
					// this.operators.push(Operators.InFiscalPeriodAndYear);
					// this.operators.push(Operators.InOrBeforeFiscalPeriodAndYear);
					// this.operators.push(Operators.InOrAfterFiscalPeriodAndYear);
					this.operators.push(Operators.OlderThanXYears);
					this.operators.push(Operators.OlderThanXWeeks);
					this.operators.push(Operators.OlderThanXDays);
					this.operators.push(Operators.OlderThanXHours);
					this.operators.push(Operators.OlderThanXMinutes);
					break;
				}

				case FilterTypes.PickList: {
					this.operators.push(Operators.Equals);
					break;
				}

				case FilterTypes.PartyList: {
					this.operators.push(Operators.Contains);
					break;
				}

				case FilterTypes.String: {
					this.operators.push(Operators.Equals);
					this.operators.push(Operators.NotEqual);
					// this.operators.push(Operators.Contains);
					// this.operators.push(Operators.DoesNotContain);
					this.operators.push(Operators.Like);
					this.operators.push(Operators.NotLike);
					this.operators.push(Operators.Null);
					this.operators.push(Operators.NotNull);
					this.operators.push(Operators.BeginsWith);
					this.operators.push(Operators.DoesNotBeginWith);
					this.operators.push(Operators.EndsWith);
					this.operators.push(Operators.DoesNotEndWith);
          break;
				}

				default: {
					this.operators.push(Operators.Equals);
					this.operators.push(Operators.NotEqual);
					break;
				}
			}


			if (this.filterBy.operator === null && this.operators.length > 0) {
				this.filterBy.operator = this.operators[0];
			}
		}

	}

	public setFilterColumn(column: GridColumn): void {
		this.filterBy!.column.name = column.name;
	}

	public applyFilter(): void {
		if (this.isValid()) {
			if (this.filterBy.operator != Operators.Between && this.filterBy.operator != Operators.NotBetween) {
				this.filterBy.optCondition = null;
			}
			this.filterByChange.emit(this.filterBy);
			this.closeDialog();
		}
		else {
			console.log('Valid', this.isValid());
		}
	}

	public cancelFilter(): void {
		this.filterBy = null!;
		this.filterByChange.emit(null!);
		this.closeDialog();
	}

	public openDialog(filter: ColumnFilter): void {
		this.filterBy = filter;
		this.dialog.nativeElement.showModal();
		this.opened.emit(null!);
	}

	public closeDialog(): void {
		this.dialog.nativeElement.close();
		this.closed.emit(null!);
	}

	private isValid(): boolean {
		if (!this.filterBy.column.name) {
			return false;
		}

		if (!this.conditionNotRequired().find(op => op == this.filterBy.operator)) {
			if (!this.filterBy.condition || this.filterBy.condition === '') {
				return false;
			}
		}

		if (this.filterBy.operator === Operators.Between || this.filterBy.operator === Operators.NotBetween) {
			if (!this.filterBy.optCondition) {
				return false;
			}
		}

		return true;
	}

	private conditionNotRequired(): Operators[] {
		const not_required: Operators[] = [];

		not_required.push(Operators.Null);
		not_required.push(Operators.NotNull);

		not_required.push(Operators.LastYear);
		not_required.push(Operators.ThisYear);
		not_required.push(Operators.NextYear);

		not_required.push(Operators.Yesterday);
		not_required.push(Operators.Today);
		not_required.push(Operators.Tomorrow);
		not_required.push(Operators.Last7Days);
		not_required.push(Operators.Next7Days);
		not_required.push(Operators.LastWeek);
		not_required.push(Operators.ThisWeek);
		not_required.push(Operators.NextWeek);
		not_required.push(Operators.LastMonth);
		not_required.push(Operators.ThisMonth);
		not_required.push(Operators.NextMonth);


		not_required.push(Operators.ThisFiscalYear);
		not_required.push(Operators.ThisFiscalPeriod);
		not_required.push(Operators.NextFiscalYear);
		not_required.push(Operators.NextFiscalPeriod);
		not_required.push(Operators.LastFiscalYear);
		not_required.push(Operators.LastFiscalPeriod);

		return not_required;
	}
}
