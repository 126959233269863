<dialog #dialog class="filter-dialog">
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner" *ngIf="filterBy">
        <h1>Filter</h1>

        <p>Show records where <span class="column-name">{{filterBy.column.label | uppercase}}</span>:</p>

        <div class="inputs">
            <label>Select Operator
                <select [(ngModel)]="filterBy.operator">
                    <option *ngFor="let operator of operators" [value]="operator">
                        {{ $any(operatorLabels[operator]) }}
                    </option>
                </select>
            </label>

            <ng-container [ngSwitch]="filterBy.type">
                <!-- Number -->
                <ng-container *ngSwitchCase="filterTypes.Number">
                    <ng-container [ngSwitch]="filterBy.operator">
                        <ng-container *ngSwitchCase="operatorTypes.Between">
                            <ng-container *ngTemplateOutlet="numberMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NotBetween">
                            <ng-container *ngTemplateOutlet="numberMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="numberInput"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- PickList -->
                <ng-container *ngSwitchCase="filterTypes.PickList">
                    <ng-container [ngSwitch]="filterBy.operator">
                        <ng-container *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="selectInput"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- Date -->
                <ng-container *ngSwitchCase="filterTypes.Date">
                    <ng-container [ngSwitch]="filterBy.operator">

                        <ng-container *ngSwitchCase="operatorTypes.Between">
                            <ng-container *ngTemplateOutlet="dateMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NotBetween">
                            <ng-container *ngTemplateOutlet="dateMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Null">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NotNull">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Yesterday">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Today">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Tomorrow">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Last7Days">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.Next7Days">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.LastWeek">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.ThisWeek">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NextWeek">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.LastMonth">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.ThisMonth">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NextMonth">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.On">
                            <ng-container *ngTemplateOutlet="dateInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NotOn">
                            <ng-container *ngTemplateOutlet="dateInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.OnOrBefore">
                            <ng-container *ngTemplateOutlet="dateInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.OnOrAfter">
                            <ng-container *ngTemplateOutlet="dateInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.LastYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.ThisYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NextYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.ThisFiscalYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.ThisFiscalPeriod">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NextFiscalYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.NextFiscalPeriod">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.LastFiscalYear">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.LastFiscalPeriod">
                            <ng-container *ngTemplateOutlet="hiddenInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.InFiscalPeriodAndYear">
                            <ng-container *ngTemplateOutlet="numberMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.InOrBeforeFiscalPeriodAndYear">
                            <ng-container *ngTemplateOutlet="numberMultiInput"></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="operatorTypes.InOrAfterFiscalPeriodAndYear">
                            <ng-container *ngTemplateOutlet="numberMultiInput"></ng-container>
                        </ng-container>


                        <ng-container *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="numberInput"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- Party List -->
                <ng-container *ngSwitchCase="filterTypes.PartyList">
                    <label>
                        <input type="text" (input)="entityQuery.next($any($event.target).value)">
                        <select [(ngModel)]="filterBy.condition">
                          <ng-container *ngIf="entities | async as ent">
                            <option *ngFor="let entity of ent" [value]="entity.id">{{ entity.name }}
                            </option>
                          </ng-container>
                        </select>
                    </label>
                </ng-container>

                <!-- Default String -->
                <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="stringInput"></ng-container>
                </ng-container>
            </ng-container>

            <ng-template #stringInput>
                <label>Text
                    <input [(ngModel)]="filterBy.condition">
                </label>
            </ng-template>

            <ng-template #numberInput>
                <label>Number
                    <input [(ngModel)]="filterBy.condition" type="number">
                </label>
            </ng-template>

            <ng-template #dateInput>
                <label>Date
                    <input [(ngModel)]="filterBy.condition" type="date">
                </label>
            </ng-template>

            <ng-template #numberMultiInput>
                <label>From
                    <input [(ngModel)]="filterBy.condition" type="number">
                </label>
                <label>To
                    <input [(ngModel)]="filterBy.optCondition" type="number">
                </label>
            </ng-template>

            <ng-template #dateMultiInput>
                <label>From
                    <input [(ngModel)]="filterBy.condition" type="date">
                </label>
                <label>To
                    <input [(ngModel)]="filterBy.optCondition" type="date">
                </label>
            </ng-template>

            <ng-template #selectInput>
                <select [(ngModel)]="filterBy.condition">
                    <option *ngFor="let opt of filterBy.column.values" [value]="opt.value">{{ opt.label }}</option>
                </select>
            </ng-template>

            <ng-template #hiddenInput>
                <input hidden [(ngModel)]="filterBy.condition" value="true">
            </ng-template>


        </div>

        <p>
            &nbsp;
            <span *ngIf="filterBy.operator == operatorTypes.Like || filterBy.operator == operatorTypes.NotLike">Use '%' for a wild-card.</span>
        </p>

        <div class="controls">
            <button (click)="applyFilter()">Apply</button>
            <button class="cancel" (click)="cancelFilter()">Clear</button>
        </div>

    </div>
</dialog>
