import {Component, Input} from '@angular/core';
import {ListView} from '../../interfaces/list-view';
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
	selector: 'app-toolbar-csv-download',
	templateUrl: './toolbar-csv-download.component.html',
	styleUrls: ['./toolbar-csv-download.component.scss'],
})
export class ToolbarCsvDownloadComponent {
	@Input() public url!: string;
	@Input() public listView!: ListView;
public get disabled(): boolean {
		return this.#disabled;
	};	@Input() public set disabled(value:unknown) {
		 this.#disabled = coerceBooleanProperty(value);
	};
	
	#disabled=false;

}
