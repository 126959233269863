import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {SiteService} from '../services/site.service';

@Component({
	selector: 'app-site-assets',
	templateUrl: './site-assets.component.html',
	styleUrls: ['./site-assets.component.scss'],
})
export class SiteAssetsComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.SiteAssets;

	public constructor(
		protected siteSvc: SiteService,
		protected entitySvc: SiteService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.siteSvc.getSiteAssets(this.id, params);
	}


	public override async goToEntity(entity: CrmEntity): Promise<void> {
		await this.router.navigate([entity.id,'details'], {relativeTo: this.activatedRoute});
	}

}
