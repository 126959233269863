import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../authentication.service';
import {User} from '../../interfaces/user';
import {NoteService} from './services/note.service';

@Component({
	selector:    'app-new-note-dialog',
	templateUrl: './new-note-dialog.component.html',
	styleUrls:   ['./new-note-dialog.component.scss'],
})
export class NewNoteDialogComponent implements OnInit {

	@ViewChild('newNoteDialog', { static: true }) dialog!: ElementRef;
	@ViewChild('attachment', { static: true }) attachment!: ElementRef;

	@Input() incidentId!: string;

	@Output() opened: EventEmitter<void>    = new EventEmitter<void>();
	@Output() closed: EventEmitter<void>    = new EventEmitter<void>();
	@Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();

	newNoteForm!: FormGroup;
	constructor(
		private noteSvc: NoteService,
		private auth: AuthenticationService,
		private builder: FormBuilder,
	) {
		this.initForm();
	}

	async ngOnInit() {

	}

	public async createNote() {
		if (this.newNoteForm.dirty && this.newNoteForm.valid) {
			try {
				this.saving.emit(true);
				this.dialog.nativeElement.close();

				const input: HTMLInputElement = this.attachment.nativeElement;
				await this.noteSvc.createNoteForIncident(this.incidentId, this.newNoteForm.value.note, input.files);

				this.newNoteForm.reset();
			} catch (ex) {
				this.openDialog();
				console.error(ex);
			} finally {
				this.saving.emit(false);
			}
		}
	}

	public closeDialog(): void {
		this.dialog.nativeElement.close();
		this.newNoteForm.reset();
		this.closed.emit(null!);
	}

	public openDialog(): void {
		console.log('open dialog');
		this.dialog.nativeElement.showModal();
		this.opened.emit(null!);
	}

	private initForm() {
		this.newNoteForm = this.builder.group({
			note:       ['', Validators.required],
			attachment: [''],
		});
	}

}
