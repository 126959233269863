import {APP_INITIALIZER, ErrorHandler, inject, NgModule} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {IsLoggedIn} from './_guards/auth.guard';
import {ErrorDialogModule} from './_shared/error-dialog/error-dialog.module';
import {ErrorHandlerService, ICErrorHandler} from './_shared/error-dialog/services/error-handler.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModule} from './login/login.module';
import {CsvExportModule} from './modules/csv-export/csv-export.module';
import {ProtectedModule} from './protected/protected.module';
import {
	HTTP_INTERCEPTORS,
	HttpContextToken, HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {AuthenticationService} from "./authentication.service";
import { FileDirective } from './file.directive';

export const SkipAuth = new HttpContextToken(() => false);

export class AuthHttpInterceptor implements HttpInterceptor {
    readonly #authenticationService = inject(AuthenticationService);
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skipAuth = req.context.get(SkipAuth).valueOf();
        if (skipAuth) {
            return next.handle(req);
        }

        const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${this.#authenticationService.token}`)
        });

        return next.handle(authReq).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					localStorage.removeItem('token');
					localStorage.removeItem('user');
                    try {
                        navigator.credentials.preventSilentAccess();
                    }
                    catch {

                    }
                    window.location.reload();
				}
				throw error;
			}),
		)
    }

}

@NgModule({
    declarations: [
        AppComponent,
    ],

    imports: [
        ProtectedModule,
        BrowserModule,
        AppRoutingModule,
        LoginModule,
        MatSnackBarModule,
        ErrorDialogModule,
        SweetAlert2Module.forRoot(),
        CsvExportModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
    ],

    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: AuthenticationService) => () => auth.silentAuth(),
            deps: [AuthenticationService],
            multi: true
        },
        IsLoggedIn,
        FormBuilder,
        ErrorHandlerService,
        {provide: ErrorHandler, useClass: ICErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    ],

    bootstrap: [
        AppComponent,
    ],
    exports: [
    ]
})
export class AppModule {

    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far);
    }
}
