<dialog #newJobDialog class="new-job">
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner">
        <h1>Add new Job</h1>
        <form [formGroup]="newJobForm" class="form" (submit)="createJob()">
            <div class="form-main">
                <div class="inputs">
                    <label>Site

                        <select formControlName="site">
                            <option disabled selected value="">Select Site</option>
                            <option *ngFor="let site of sites$ | async" [value]="site.id">{{ site.label }}</option>
                        </select>
                    </label>

                    <label class="expanded">Brief Description
                        <input formControlName="title" maxlength="100"/>
                    </label>

                    <label class="expanded">Further Information
                        <textarea formControlName="description"></textarea>
                    </label>

                    <label class="expanded">PO/Work Order
                        <input formControlName="workOrder"/>
                    </label>

                    <label class="expanded">Attachments
                      <input #fileUpload type="file" multiple accept="image/*,.pdf"  (change)="handleFiles(fileUpload)"/>
                    </label>
                  </div>
            </div>
            <div class="form-sidebar">
                <div class="inputs">
                    <label>Priority
                        <select formControlName="priority">
                            <option disabled selected value="">Select Priority</option>
                            <option *ngFor="let priority of priorities$ | async" [value]="priority.id">{{ priority.label }}</option>
                        </select>
                    </label>
                </div>
                <div class="controls">
                    <button class="success" [class.disabled]="!newJobForm.valid">submit</button>
                </div>
            </div>
        </form>
    </div>
</dialog>
