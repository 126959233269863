export enum FilterTypes {
	None,
	PickList,
	Boolean,
	String,
	Number,
	Date,
	Lookup,
	Virtual,
	Alias,
	Entity,
	PartyList,
}