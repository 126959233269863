import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'riDate',
})
export class RiDatePipe implements PipeTransform {

	transform(value: any, display = true): any {
		if (display) {
			try {
				// DD/MM/YYYY HH:MM
				// const date = new DatePipe('en-GB').transform(value, 'dd/MM/yyyy HH:mm');
				// if (date.split(' ')[1] === '00:00') {
				// 	return new DatePipe('en-GB').transform(date, 'dd/MM/yyyy');
				// }
				return value;

			} catch (ex) {
				return value;
			}

		} else {
			//	convert for sending to API
		}
	}

}
