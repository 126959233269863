import {HttpClient, HttpHeaders as Headers} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmEntity, Entities} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {APIService} from '../../jobs/services/jobs.service';
import {firstValueFrom} from "rxjs";

@Injectable()
export class AssetService extends APIService {
	protected _api =  '/api/assets';

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public getAssets(params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/`, params);
	}
	public getChildAssets(id:string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/`+ id,  params);
	}

	public getAsset(id: string): Promise<CrmEntity> {
		const headers = new Headers();
		headers.append('Authorization', this.auth.getAuthorization());

		return firstValueFrom(this.http.get<CrmEntity>(`${this._api}/${id}`, {headers}))
	}
}
