import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../directives/directives.module';
import {NewNoteDialogComponent} from './new-note-dialog.component';
import {NoteService} from './services/note.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
	],
	providers: [NoteService],
	declarations: [NewNoteDialogComponent],
	exports: [NewNoteDialogComponent],
})
export class NewNoteDialogModule {
}
