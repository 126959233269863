import {CrmEntity} from './entity';

export function proxyCrmEntities<T>(entities: CrmEntity[]): (T & CrmEntity)[] {
	return entities.map(entity => proxyCrmEntity(entity));
}

export function proxyCrmEntity<T>(entity: CrmEntity): T & CrmEntity {
	return <any> new Proxy(entity, {
		get(target, property, receiver) {
			const attribute = target.attributes.find(attr => attr.key === property);
			if (attribute) {
				return attribute.value;
			}
			else {
				return Reflect.get(target, property);
			}
		},
	});
}