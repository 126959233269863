export enum Operators {
	Equals                           = 0,
	NotEqual                         = 1,
	GreaterThan                      = 2,
	LessThan                         = 3,
	GreaterEqual                     = 4,
	LessEqual                        = 5,
	Like                             = 6,
	NotLike                          = 7,
	In                               = 8,
	NotIn                            = 9,
	Between                          = 10,
	NotBetween                       = 11,
	Null                             = 12,
	NotNull                          = 13,
	Yesterday                        = 14,
	Today                            = 15,
	Tomorrow                         = 16,
	Last7Days                        = 17,
	Next7Days                        = 18,
	LastWeek                         = 19,
	ThisWeek                         = 20,
	NextWeek                         = 21,
	LastMonth                        = 22,
	ThisMonth                        = 23,
	NextMonth                        = 24,
	On                               = 25,
	OnOrBefore                       = 26,
	OnOrAfter                        = 27,
	LastYear                         = 28,
	ThisYear                         = 29,
	NextYear                         = 30,
	LastXHours                       = 31,
	NextXHours                       = 32,
	LastXDays                        = 33,
	NextXDays                        = 34,
	LastXWeeks                       = 35,
	NextXWeeks                       = 36,
	LastXMonths                      = 37,
	NextXMonths                      = 38,
	LastXYears                       = 39,
	NextXYears                       = 40,
	EqualUserId                      = 41,
	NotEqualUserId                   = 42,
	EqualBusinessId                  = 43,
	NotEqualBusinessId               = 44,
	ChildOf                          = 45,
	Mask                             = 46,
	NotMask                          = 47,
	MasksSelect                      = 48,
	Contains                         = 49,
	DoesNotContain                   = 50,
	EqualUserLanguage                = 51,
	NotOn                            = 52,
	OlderThanXMonths                 = 53,
	BeginsWith                       = 54,
	DoesNotBeginWith                 = 55,
	EndsWith                         = 56,
	DoesNotEndWith                   = 57,
	ThisFiscalYear                   = 58,
	ThisFiscalPeriod                 = 59,
	NextFiscalYear                   = 60,
	NextFiscalPeriod                 = 61,
	LastFiscalYear                   = 62,
	LastFiscalPeriod                 = 63,
	LastXFiscalYears                 = 64,
	LastXFiscalPeriods               = 65,
	NextXFiscalYears                 = 66,
	NextXFiscalPeriods               = 67,
	InFiscalYear                     = 68,
	InFiscalPeriod                   = 69,
	InFiscalPeriodAndYear            = 70,
	InOrBeforeFiscalPeriodAndYear    = 71,
	InOrAfterFiscalPeriodAndYear     = 72,
	EqualUserTeams                   = 73,
	EqualUserOrUserTeams             = 74,
	Under                            = 75,
	NotUnder                         = 76,
	UnderOrEqual                     = 77,
	Above                            = 78,
	AboveOrEqual                     = 79,
	EqualUserOrUserHierarchy         = 80,
	EqualUserOrUserHierarchyAndTeams = 81,
	OlderThanXYears                  = 82,
	OlderThanXWeeks                  = 83,
	OlderThanXDays                   = 84,
	OlderThanXHours                  = 85,
	OlderThanXMinutes                = 86
}

export const OperatorLabels:Partial<Record<Operators, string>> = {
	[Operators.Equals]:                        'Is Equal to',
	[Operators.NotEqual]:                      'Is Not Equal to',
	[Operators.GreaterThan]:                   'Is Greater Than',
	[Operators.LessThan]:                      'Is Less Than',
	[Operators.Contains]:                      'Contains',
	[Operators.GreaterEqual]:                  'Is Greater Than or is Equal',
	[Operators.LessEqual]:                     'Is Less Than or is Equal',
	[Operators.Like]:                          'Is Like',
	[Operators.NotLike]:                       'Is Not Like',
	//[Operators.In]: 			'In',
	//[Operators.NotIn]: 			'Not In',
	// [Operators.Between]: 		'Between',
	// [Operators.NotBetween]: 	'Not Between',
	[Operators.Between]:                       'Is Between',
	[Operators.NotBetween]:                    'Is Not Between',
	[Operators.Null]:                          'Is Null',
	[Operators.NotNull]:                       'Is Not Null',
	[Operators.Yesterday]:                     'Yesterday',
	[Operators.Today]:                         'Today',
	[Operators.Tomorrow]:                      'Tomorrow',
	[Operators.Last7Days]:                     'Last 7 Days',
	[Operators.Next7Days]:                     'Next 7 Days',
	[Operators.LastWeek]:                      'Last Week',
	[Operators.ThisWeek]:                      'This Week',
	[Operators.NextWeek]:                      'Next Week',
	[Operators.LastMonth]:                     'Last Month',
	[Operators.ThisMonth]:                     'This Month',
	[Operators.NextMonth]:                     'Next Month',
	[Operators.On]:                            'On',
	[Operators.OnOrBefore]:                    'On or Before',
	[Operators.OnOrAfter]:                     'On or After',
	[Operators.LastYear]:                      'Last Year',
	[Operators.ThisYear]:                      'This Year',
	[Operators.NextYear]:                      'Next Year',
	[Operators.LastXHours]:                    'Last X Hours',
	[Operators.NextXHours]:                    'Next X Hours',
	[Operators.LastXDays]:                     'Last X Days',
	[Operators.NextXDays]:                     'Next X Days',
	[Operators.LastXWeeks]:                    'Last X Weeks',
	[Operators.NextXWeeks]:                    'Next X Weeks',
	[Operators.LastXMonths]:                   'Last X Months',
	[Operators.NextXMonths]:                   'Next X Months',
	[Operators.LastXYears]:                    'Last X Years',
	[Operators.NextXYears]:                    'Next X Years',
	//[Operators.EqualUserId]: 	'Equal User ID',
	//[Operators.NotEqualUserId]: 	'Not Equal User ID',
	//[Operators.EqualBusinessId]: 	'Equal Business ID',
	//[Operators.NotEqualBusinessId]: 'Not Equal Business ID',
	//[Operators.ChildOf]: 			'Child Of',
	// [Operators.Mask]: 				'Mask',
	// [Operators.NotMask]: 			'Not Mask',
	// [Operators.MasksSelect]: 		'Masks Select',
	[Operators.Contains]:                      'Contains',
	[Operators.DoesNotContain]:                'Does Not Contain',
	//[Operators.EqualUserLanguage]: 	'Equal User Language',
	[Operators.NotOn]:                         'Not On',
	[Operators.OlderThanXMonths]:              'Is Older Than X Months',
	[Operators.BeginsWith]:                    'Begins With',
	[Operators.DoesNotBeginWith]:              'Does Not Begin With',
	[Operators.EndsWith]:                      'Ends With',
	[Operators.DoesNotEndWith]:                'Does Not End With',
	[Operators.ThisFiscalYear]:                'This Fiscal Year',
	[Operators.ThisFiscalPeriod]:              'This Fiscal Period',
	[Operators.NextFiscalYear]:                'Next Fiscal Year',
	[Operators.NextFiscalPeriod]:              'Next Fiscal Period',
	[Operators.LastFiscalYear]:                'Last Fiscal Year',
	[Operators.LastFiscalPeriod]:              'Last Fiscal Period',
	[Operators.LastXFiscalYears]:              'Last X Fiscal Years',
	[Operators.LastXFiscalPeriods]:            'Last X Fiscal Periods',
	[Operators.NextXFiscalYears]:              'Next X Fiscal Years',
	[Operators.NextXFiscalPeriods]:            'Next X Fiscal Periods',
	[Operators.InFiscalYear]:                  'In Fiscal Year',
	[Operators.InFiscalPeriod]:                'In Fiscal Period',
	[Operators.InFiscalPeriodAndYear]:         'In Fiscal Period and Year',
	[Operators.InOrBeforeFiscalPeriodAndYear]: 'In or Before Fiscal Period and Year',
	[Operators.InOrAfterFiscalPeriodAndYear]:  'In or After Fiscal Period and Year',
	// [Operators.EqualUserTeams]: 	'Equal User Teams',
	// [Operators.EqualUserOrUserTeams]: 'Equal User or User Teams',
	[Operators.Under]:                         'Is Under',
	[Operators.NotUnder]:                      'Is Not Under',
	[Operators.UnderOrEqual]:                  'Is Under or is Equal',
	[Operators.Above]:                         'Is Above',
	[Operators.AboveOrEqual]:                  'Is Above or is Equal',
	// [Operators.EqualUserOrUserHierarchy]: 'Equal User or User Hierarchy',
	// [Operators.EqualUserOrUserHierarchyAndTeams]: 'Equal User or User Hierarchy and Teams',
	[Operators.OlderThanXYears]:               'Is Older Than X Years',
	[Operators.OlderThanXWeeks]:               'Is Older Than X Weeks',
	[Operators.OlderThanXDays]:                'Is Older Than X Days',
	[Operators.OlderThanXHours]:               'Is Older Than X Hours',
	[Operators.OlderThanXMinutes]:             'Is Older Than X Minutes',
};
