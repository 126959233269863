import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {CrmEntity} from "../../crm/entity";
import {firstValueFrom} from "rxjs";

@Injectable()
export class SitesService {
	private _api: string =  '/api/sites';

	public constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	public getAllFromContract(contract_id: string): Promise<CrmEntity[]> {
		const params = new URLSearchParams();
		params.set('contract', contract_id);
		
		return firstValueFrom(this.http.get<CrmEntity[]>(`${this._api}/search?${params}`));
	}
}
