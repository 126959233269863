<ng-container *ngIf="csvExportService.downloads && csvExportService.downloads.length > 0">
    <h3 class="panel-header">
        Downloads
    </h3>

    <ng-container *ngFor="let download of csvExportService.downloads">
        <div class="download-container">
            <div class="download-details">
                <h4>{{download.filename}}</h4>
                <div class="progress-bar">
                    <progress
                        *ngIf="download.progress > -1"
                        max="1"
                        value="{{download.progress}}"
                        [title]="download.recordCount + ' - ' + download.progress+'%'"
                    ></progress>
                    <progress *ngIf="download.progress == -1"
                              [title]="download.recordCount"></progress>
                </div>
            </div>
            <div class="download-controls">
                <a
                    *ngIf="download.progress >= 1"
                    class="btn btn-success"
                    style="cursor: pointer;"
                    (click)="downloadFile(download)"
                >View</a>
                <a
                    *ngIf="download.progress >= 1"
                    class="btn btn-success"
                    style="cursor: pointer;"
                    (click)="csvExportService.removeDownload(download)"
                >Close</a>
                <a
                    *ngIf="download.progress < 1"
                    class="btn btn-success"
                    style="cursor: pointer;"
                    (click)="csvExportService.cancelDownload(download)"
                >Cancel</a>
            </div>
        </div>
    </ng-container>
</ng-container>