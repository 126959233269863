import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CsvExportService} from '../csv-export.service';
import {Download} from '../download';

@Component({
	selector: 'app-download-panel',
	templateUrl: './download-panel.component.html',
	styleUrls: ['./download-panel.component.scss'],
})
export class DownloadPanelComponent implements OnInit, OnDestroy {
	private downloadProgress!: Subscription;

	public constructor(
		public csvExportService: CsvExportService,
	) {
	}

	public ngOnInit() {
		this.downloadProgress = this.csvExportService.$downloadProgress.subscribe();
	}

	public ngOnDestroy(): void {
		this.downloadProgress.unsubscribe();
	}

	public downloadFile(download: Download) {
		const a = document.createElement('a');
		a.href = `/csv/${download.id}`;
		a.download = download.filename;
		a.click();
	}
}
