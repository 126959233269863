import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {NewJobDialogComponent} from '../../../_shared/new-job-dialog/new-job-dialog.component';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {MetadataService} from '../../../_shared/services/metadata.service';
import {AuthenticationService} from '../../../authentication.service';
import {Contract} from '../../../interfaces/contract';
import {CsvExportService} from '../../../modules/csv-export/csv-export.service';
import {Download} from '../../../modules/csv-export/download';
import {EntityList} from '../../_controllers/entity-list';
import {JobsService} from '../services/jobs.service';
import {map} from "rxjs/operators";

@Component({
	selector:    'app-jobs-list',
	templateUrl: './jobs-list.component.html',
	styleUrls:   ['./jobs-list.component.scss'],
})
export class JobsListComponent extends EntityList implements OnInit{
	@ViewChild(NewJobDialogComponent, { static: true }) public newJobDialog!: NewJobDialogComponent;
	public listViewType: ListViewTypes = ListViewTypes.Jobs;

	contract$ = this.auth.currentContract;
	canCreateJob$ = this.contract$.pipe(
		map(contract => contract?.canCreateJob ?? false)
	);
	
	public constructor(
		protected entityService: JobsService,
		protected listViewSvc: ListViewService,
		protected metadataSvc: MetadataService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
		protected http: HttpClient,
		public csvExportService: CsvExportService,
	) {
		super();

	}

	public override async ngOnInit(): Promise<void> {
		this.listLayout.modifyColumn('ri_kpistatusdashboard', ( value) => {
			let style = '';
			switch (value) {
				case 'Fail':
					style='kpiFail';
					break;
				case 'Pass':
					style='kpiPass';
					break;
				case 'In Progress':
					style='kpiProgress';
					break;
			}
			return `<span class="${style}"></span> ${value}`;
		});

		await super.ngOnInit();
	}

	public openNewJobDialog(): void {
		this.newJobDialog.openDialog();
	}

	public handleNewJobSaving(saving: boolean): void {
		if (saving) {
			this.loader.show('Creating new job');
		} else {
			this.loader.dismiss();
		}
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.entityService.getJobs(params);
	}

	public async downloadCSV() {
		const contract: Contract = this.auth.currentContract.getValue();
		const headers = new HttpHeaders().append('Authorization', this.auth.getAuthorization());
		const params = new HttpParams({fromString: `${this.generateQueryParams()}`}).set('contractId', contract.id);

		const csvExport = await this.http.get<Download>(`/api/jobs/csv`, {
			headers,
			params,
		}).toPromise();

		this.csvExportService.addDownload(csvExport!);
	}
}
