import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BarChartModule, NgxChartsModule} from '@swimlane/ngx-charts';
import {AssetsModule} from './assets/assets.module';
import {ChangePasswordComponent} from './home/change-password/change-password.component';
import {HomeComponent} from './home/home.component';
import {VarDirective} from './home/var.directive';
import {JobsModule} from './jobs/jobs.module';
import {NavbarWrapperModule} from './navbar-wrapper/navbar-wrapper.module';
import {PaperworksModule} from './paperworks/paperworks.module';
import {SitesModule} from './sites/sites.module';
import {VisitsModule} from './visits/visits.module';
import { ChartComponent } from './home/chart/chart.component';
import { ChartsXAxisComponent } from './home/chart/charts-x-axis/charts-x-axis.component';
import { ChartsAxisLabelComponent } from './home/chart/charts-axis-label/charts-axis-label.component';
import { ChartsXAxisTicksComponent } from './home/chart/charts-x-axis-ticks/charts-x-axis-ticks.component';
import {SharedModule} from "../_shared/shared.module";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        JobsModule,
        VisitsModule,
        SitesModule,
        AssetsModule,
        PaperworksModule,
        NavbarWrapperModule,
        BarChartModule,
        NgxChartsModule,
        SharedModule,
    ],
	declarations: [HomeComponent, VarDirective, ChartComponent, ChartsXAxisComponent, ChartsAxisLabelComponent, ChartsXAxisTicksComponent],
	exports: [
	],
})
export class ProtectedModule {
}
