import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {Contract} from '../../../interfaces/contract';
import {CsvExportService} from '../../../modules/csv-export/csv-export.service';
import {Download} from '../../../modules/csv-export/download';
import {EntityList} from '../../_controllers/entity-list';
import {VisitService} from '../services/visit.service';
import {VisitStatusService} from './popup/visit-status.service';

@Component({
	selector: 'app-visits-list',
	templateUrl: './visits-list.component.html',
	styleUrls: ['./visits-list.component.scss'],
})
export class VisitsListComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.Visits;

	public constructor(
		protected entitySvc: VisitService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
		private visitStatusServive: VisitStatusService,
		private http: HttpClient,
		public csvExportService: CsvExportService,
	) {
		super();

	}

	public override async ngOnInit(): Promise<void> {
		await super.ngOnInit();
		this.auth.currentContract.subscribe(contract => {
			if (contract && contract.isSubContractor) {
				this.addVisitStatusColumn();
			}
		});

		this.visitStatusServive.dialogClosed
			.subscribe(() => this.handleRefreshClick());
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.entitySvc.getVisits(params);
	}

	public async downloadCSV() {
		const contract: Contract = this.auth.currentContract.getValue();
		const headers = new HttpHeaders().append('Authorization', this.auth.getAuthorization());
		const params = new HttpParams({fromString: `${this.generateQueryParams()}`}).set('contractId', contract.id);

		const csvExport = await this.http.get<Download>(`/api/visits/csv`, {
			headers,
			params,
		}).toPromise();

		this.csvExportService.addDownload(csvExport!);
	}

	protected addVisitStatusColumn(): void {
		if (!this.listLayout) {
			return;
		}
		this.listLayout.addColumn('statuscode', 'Status Reason', 100,
			entity => {
				const status = entity.formattedValues.find(attr => attr.key === 'statuscode');
				return status ? status.value : 'Update Status';
			},

			entity => {
				this.visitStatusServive.show(entity);
			},
		);
	}
}
