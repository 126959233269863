import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'tabIsVisible',
})
export class TabIsVisiblePipe implements PipeTransform {

	transform(tabs: Array<any>, visible?: boolean): any {
//		console.log('visible bool', visible)
		if (visible === undefined) {
			return tabs.filter(tab => tab.isVisible === true);
		}
		else {
			return tabs.filter(tab => tab.isVisible === visible);
		}
	}

}
