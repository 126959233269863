<svg:g [attr.class]="xAxisClassName" [attr.transform]="transform">
    <svg:g
        app-charts-x-axis-ticks
        *ngIf="xScale"
        [attr.trimTicks]="trimTicks"
        [attr.rotateTicks]="rotateTicks"
        [attr.maxTickLength]="maxTickLength"
        [attr.tickFormatting]="tickFormatting"
        [attr.tickArguments]="tickArguments"
        [attr.tickStroke]="tickStroke"
        [attr.scale]="xScale"
        [attr.orient]="xOrient"
        [attr.showGridLines]="showGridLines"
        [attr.gridLineHeight]="dims.height"
        [attr.width]="dims.width"
        [attr.tickValues]="ticks"
        (dimensionsChanged)="emitTicksHeight($event)"
    />
    <svg:g
        ng-charts-axis-label
        *ngIf="showLabel"
        [attr.label]="labelText"
        [attr.offset]="labelOffset"
        [attr.orient]="'bottom'"
        [attr.height]="dims.height"
        [attr.width]="dims.width"
    ></svg:g>
</svg:g>
