import {HttpResponse} from '@angular/common/http';
import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {LoaderComponent} from '../../../ri-loader-component/loader.component';
import CaughtError from '../../_shared/caught-error';
import {CrmEntity, Entities} from '../../_shared/crm/entity';
import {ListViewTypes} from '../../_shared/list-view-types.enum';
import {Filter} from '../../_shared/page-toolbar/filter';
import {ListViewService} from '../../_shared/services/list-view.service';
import {XmlListLayoutComponent} from '../../_shared/xml-list-layout/xml-list-layout.component';
import {AuthenticationService} from '../../authentication.service';
import {ListView} from '../../interfaces/list-view';

export interface PaginationParameters {
	page: number;
	count: number;
}

export interface SortParameters {
	column: string;
	entity: string;
	direction: string;
}

export interface FilterParameters {
	entity: string;
	column: string;
	operator: number;
	value: string[];
}

@Directive()
export abstract class EntityList implements OnInit, OnDestroy {
	@ViewChild(LoaderComponent, { static: true }) public loader!: LoaderComponent;
	@ViewChild(XmlListLayoutComponent, { static: true }) public listLayout!: XmlListLayoutComponent;

	public id!: string;
	public entities: Entities = Object.assign([], {
		totalRecordCount:              -1,
		totalRecordCountLimitExceeded: false,
		moreRecords:                   true,
	});

	public selectedView!: ListView;
	public filter!: Filter;
	public filterName!: string;
  public searchValue: string | null = null;

	// Pagination
	public currentPage = 1;
	public maxRecords  = 25;
	public abstract listViewType: ListViewTypes;

	// Imported Angular things
	protected abstract listViewSvc: ListViewService;
	protected abstract router: Router;
	protected abstract activatedRoute: ActivatedRoute;
	protected toast!: MatSnackBar;
	protected auth!: AuthenticationService;
	private contractSubscription!: Subscription;

	public get count() {
		function check(obj: any): obj is Entities {
			return obj.hasOwnProperty('totalRecordCount');
		}

		if (check(this.entities)) {
			return this.entities.totalRecordCount;
		}

		return -1;
	};

	public get countLimitExceeded() {
		function check(obj: any): obj is Entities {
			return obj.hasOwnProperty('totalRecordCountLimitExceeded');
		}

		if (check(this.entities)) {
			return this.entities.totalRecordCountLimitExceeded;
		}

		return false;
	};

	public async ngOnInit(): Promise<void> {
		this.loader.show();

		this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
			this.id = params.get('id')!;
		});

		this.activatedRoute.queryParamMap.subscribe(async (params: ParamMap) => {
			this.currentPage = parseInt(params.get('page')!, 10) || 1;
			try {
				this.loader.show();
				await this.populateView();
			}
			finally {
				this.loader.dismiss();
			}
		});

		if (this.auth) {
			// this.auth.state
			// 	.filter(state => state !== 'pending')
			// 	.subscribe(async () => {
			// 		try {
			// 			this.loader.show();
			// 			await this.getListViews();
			// 		}
			// 		finally {
			// 			this.loader.dismiss();
			// 		}
			// 	});

			this.contractSubscription = this.auth.currentContract.subscribe(async () => {
				try {
					this.loader.show();
					await this.getListViews();
					await this.populateView();
				}
				finally {
					this.loader.dismiss();
				}
			});
		}

		try {
			// await this.getListViews();
			await this.populateView();
		}
		finally {
			this.loader.dismiss();
		}
	}

	public async ngOnDestroy(): Promise<void> {
		if (this.contractSubscription) {
			this.contractSubscription.unsubscribe();
		}
	}

	public async changeView(selectedView: ListView): Promise<void> {
    this.listLayout.selectionModel.clear();
    this.selectedView = selectedView;
    this.currentPage=1;
		try {
			this.loader.show();
			await this.populateView();
		}
		finally {
			this.loader.dismiss();
		}
	}

	public async goToEntity(entity: CrmEntity): Promise<void> {
		await this.router.navigate([entity.id], {relativeTo: this.activatedRoute});
	}

	public async populateView(): Promise<void> {
		// this.entities.length = 0;
		if (!this.selectedView) {
			return;
		}

		const params = this.generateQueryParams();

		const entities = await this.getEntities(params);

		function check(obj: object): obj is Entities {
			return obj.hasOwnProperty('totalRecordCount');
		}

		if (check(entities)) {
			this.entities = entities;
		}
		else {
			this.entities = Object.assign(entities, {
				totalRecordCount: -1,
				totalRecordCountLimitExceeded: false,
				moreRecords: true,
			});
		}
	}

	public generateQueryParams() {
		// Add page and count to query.fetchxml
		this.selectedView.fetchXml.count = this.maxRecords;
		this.selectedView.fetchXml.page = this.currentPage;

		const {currentPage: page, maxRecords: count} = this;

		let pagination: PaginationParameters;
		let sort: SortParameters;
		let filter: FilterParameters;

		pagination = {
			page,
			count,
		};

		if (this.listLayout.columnSort) {
			sort = {
				column: this.listLayout.columnSort.column,
				entity: this.listLayout.columnSort.entity,
				direction: this.listLayout.columnSort.direction,
			};
		}

		if (this.listLayout.columnFilter) {
			filter = {
				column: this.listLayout.columnFilter.column.name,
				entity: this.listLayout.columnFilter.entity,
				operator: this.listLayout.columnFilter.operator,
				value: Array.isArray(this.listLayout.columnFilter.condition) ? this.listLayout.columnFilter.condition : [this.listLayout.columnFilter.condition],
			};
			if (this.listLayout.columnFilter.optCondition) {
				filter.value.push(this.listLayout.columnFilter.optCondition);
			}
		}


		const params = new URLSearchParams();

		params.set('queryId', `${this.selectedView.savedQueryId}`);

		// Pagination
		if (pagination) {
			params.set('page', `${pagination.page}`);
			params.set('count', `${pagination.count}`);
		}

		// Sort
		if (sort!) {
			params.set('sort', `${sort.column}`);
			params.set('sortEntity', `${sort.entity}`);
			params.set('direction', `${sort.direction}`);
		}

		// Filter
		if (filter!) {
			params.set('filter', `${filter.column}`);
			params.set('filterEntity', `${filter.entity}`);
			params.set('operator', `${filter.operator}`);
			for (const value of filter.value) {
				params.append('value', `${value}`);
			}
		}

    if (this.searchValue) {
      params.set('search', `%${this.searchValue}%`);
    }

		return params;
	}

	public async handleViewChange(): Promise<void> {
		if (this.currentPage === 1) {
			try {
				this.loader.show();
				await this.populateView();
			}
			finally {
				this.loader.dismiss();
			}
		} else {
			await this.router.navigate([this.activatedRoute.snapshot.url], {queryParams: {page: 1}});
		}
	}

	public async handleRefreshClick(): Promise<void> {
		try {
			this.loader.show();
			await this.populateView();
		}
		finally {
			this.loader.dismiss();
		}
	}
	public async back(): Promise<void> {
		await this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
	}

	protected async errorToast<T extends Error>(error: any): Promise<void> {
		console.error(error);

		if (error instanceof HttpResponse) {
			throw error;
		}

		else if (error instanceof CaughtError) {
			this.toast.open(error.message, undefined, {duration:5000});
			console.error(error.caughtError);
		}

		else if (error instanceof Error) {
			this.toast.open(error.message, undefined, {duration:5000});
		}

		else if (typeof error === 'string') {
			this.toast.open(error, undefined, {duration:5000});
		}

		throw error;
	}

	protected abstract getEntities(params: URLSearchParams): Promise<(CrmEntity[] | Entities)>;

	private async getListViews() {
		const views: ListView[] = await this.listViewSvc.getViews(this.listViewType);

		if (views.length === 0) {
			throw (CaughtError.Warning('No views available', 'Please contact your system administrator.', new Error()));
			// await this.errorToast(new Error('No views available for this page, please contact your system administrator.'));
		}


		this.filter = {
			name:    this.filterName,
			options: views,
		};

		this.selectedView = this.filter.options[0];
	}

  search($event: string | null) {
    this.searchValue = $event;
    this.currentPage = 1;
    this.populateView();
  }
}
