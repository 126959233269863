import {Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {AuthenticationService} from '../../authentication.service';
import {JobService} from './services/job.service';
import {SitesService} from './services/sites.service';
import {Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ContractService} from "../../contract.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-job-dialog',
  templateUrl: './new-job-dialog.component.html',
  styleUrls: ['./new-job-dialog.component.scss'],
})
export class NewJobDialogComponent {
  readonly #router = inject(Router);
  @ViewChild('newJobDialog', {static: true}) dialog!: ElementRef;
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();

  newJobForm!: FormGroup<{
    site: FormControl<string>
    title: FormControl<string>
    description: FormControl<string>
    priority: FormControl<string>
    workOrder: FormControl<string>
  }>;

  readonly sites$: Observable<{ id: string; label: string; }[]> = this.auth.currentContract.pipe(
    switchMap(contract => this.contractService.getContractSites(contract.id))
  );

  readonly priorities$: Observable<{ id: string; label: string; }[]> = this.auth.currentContract.pipe(
    switchMap(contract => this.contractService.getContractPriorities(contract.id))
  );

  constructor(
    private jobSvc: JobService,
    private siteSvc: SitesService,
    private contractService: ContractService,
    private auth: AuthenticationService,
    private builder: NonNullableFormBuilder,
  ) {
    this.newJobForm = this.builder.group({
      site: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      priority: ['', Validators.required],
      workOrder: ['', Validators.required],
    });
  }

  protected files:File[] = [];


  public async createJob() {
    if (this.newJobForm.dirty && this.newJobForm.valid) {
      try {
        this.saving.emit(true);
        this.dialog.nativeElement.close();

        const job = {
          contractId: this.auth.currentContract.getValue().id,
          siteId: this.newJobForm.value.site!,
          priorityId: this.newJobForm.value.priority!,
          title: this.newJobForm.value.title!,
          description: this.newJobForm.value.description!,
          workOrder: this.newJobForm.value.workOrder!,
        }!;

        const incident = await this.jobSvc.create(job);

        await this.jobSvc.uploadAttachment(incident.id, this.files)

        await this.#router.navigate(['/jobs', incident.id, 'details'])
      } catch (ex) {
        this.openDialog();
        console.error(ex);
      } finally {
        this.saving.emit(false);
      }
    }
  }

  public closeDialog(): void {
    this.dialog.nativeElement.close();
    this.newJobForm.reset();
    this.closed.emit(null!);
  }

  public openDialog(): void {
    this.dialog.nativeElement.showModal();
    this.opened.emit(null!);
  }

  protected handleFiles(elm:HTMLInputElement) {
    this.files = Array.from(elm.files??[]);
  }
}
