import {
	AfterContentInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	Input,
	OnChanges,
	OnInit, QueryList,
} from '@angular/core';
import {NgSelectOption, SelectControlValueAccessor} from '@angular/forms';
import {ListView} from '../../interfaces/list-view';
import {OptionDirective} from './option.directive';

@Component({
	selector: 'app-toolbar-select',
	templateUrl: './toolbar-select.component.html',
	styleUrls: ['./toolbar-select.component.scss'],
})
export class ToolbarSelectComponent  {
	@Input()
	loading = false;

	@ContentChild('select', {read: SelectControlValueAccessor}) select!: SelectControlValueAccessor;
	@ContentChildren(NgSelectOption, {descendants:true,read: OptionDirective }) options!: QueryList<OptionDirective>;
	public selectOpen!: boolean;

	ngAfterContentInit() {
		console.log(this.select);
	}


	public handleOptionClick(option: OptionDirective): void {
		console.log(option.ngValue);
		console.log(option.value);
		this.select.writeValue(option.value);
	}
}
