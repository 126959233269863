import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RiDatePipe} from './ri-date.pipe';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [RiDatePipe],
	declarations: [RiDatePipe],
})
export class PipesModule {
}
