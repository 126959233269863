import {Component, ViewEncapsulation} from '@angular/core';
import {BarVerticalNormalizedComponent} from '@swimlane/ngx-charts';
import {BarChartType} from "@swimlane/ngx-charts";

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChartComponent extends BarVerticalNormalizedComponent {
  type = BarChartType.Standard;

}
