import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderComponent} from '../../ri-loader-component/loader.component';
import CaughtError from '../_shared/caught-error';
import {AuthenticationService} from '../authentication.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    @ViewChild(LoaderComponent, {static: true}) loader!: LoaderComponent;

    loginForm!: FormGroup;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authSvc: AuthenticationService,
    ) {
        // get return url from route parameters
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        });

        try {
            navigator.credentials.get({password: true}).then(async (cred) => {
                if (cred?.type === 'password') {
                    this.loginForm.controls['username'].setValue(cred.id);
                    this.loginForm.controls['password'].setValue(cred.password);

                    this.loader.show('Checking credentials');
                    try {
                        await this.authSvc.login(this.loginForm.value.username, this.loginForm.value.password);
                        await this.router.navigate([this.returnUrl]);
                    } catch (ex) {
                        console.error(ex);
                        throw (CaughtError.Info('Login Failed', 'Please check your credentials.', new Error()));
                    } finally {
                        this.loader.dismiss();
                    }
                }
            }).catch(console.error);
        }
        catch (ex) {
            console.error(ex);
        }
    }

    async submitLogin(): Promise<void> {
        if (this.loginForm.valid) {
            this.loader.show('Checking credentials');
            try {
                await this.authSvc.login(this.loginForm.value.username, this.loginForm.value.password);
                await this.router.navigate([this.returnUrl]);
            } catch (ex) {
                console.error(ex);
                throw (CaughtError.Info('Login Failed', 'Please check your credentials.', new Error()));
            } finally {
                this.loader.dismiss();
            }
        } else {
            // todo: Validation messages
            alert('Form invalid');

        }
    }

}
