import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

import {CrmEntity} from '../../../_shared/crm/entity';
import {FormViewTypes} from '../../../_shared/form-view-types.enum';
import {FormViewService} from '../../../_shared/services/form-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityForm} from '../../_controllers/entity-form';
import {JobsService} from '../services/jobs.service';

@Component({
	selector: 'app-job-details',
	templateUrl: './job-details.component.html',
	styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent extends EntityForm {
	public formViewType: FormViewTypes = FormViewTypes.JobDetails;

	constructor(
		protected formViewService: FormViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		private jobSvc: JobsService,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	protected getEntity(guid: string): Promise<CrmEntity> {
		return this.jobSvc.getJob(guid);
	}
}
