import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DirectivesModule} from '../directives/directives.module';
import {ErrorDialogComponent} from './error-dialog.component';
import {ErrorHandlerService} from './services/error-handler.service';

@NgModule({
	imports:      [
		CommonModule,
		MatSnackBarModule,
		DirectivesModule,
	],
	exports:      [ErrorDialogComponent],
	declarations: [ErrorDialogComponent],
	providers:    [ErrorHandlerService],
})
export class ErrorDialogModule {
}
