import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ri-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  @Input() loading!: boolean;
  @Input() text!: string;

  constructor() { }

  ngOnInit() {
  }

  show(msg?: string) {
	  this.text = msg || 'Loading';
    this.loading = true;
  }

  dismiss() {
    this.loading = false;
  }

}
