import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BarVerticalNormalizedComponent} from '@swimlane/ngx-charts';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {map, mapTo, startWith, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../../authentication.service';
import {Contract} from '../../interfaces/contract';
import {User} from '../../interfaces/user';
import {ScaleType} from "@swimlane/ngx-charts";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	public user$: Observable<{ name: string }> = this.authService.currentUser;

	public $currentContract!: Observable<Contract>;
	public scope = new FormControl('current');

	@ViewChild('chart', {static: false})
	public chart!: BarVerticalNormalizedComponent;

	public colorScheme = {
    name: '',
    selectable: false,
    group: ScaleType.Linear,
		domain: [
			// Pass
			'#00FF00',
			// In Progress
			'#ff8000',
			// Fail
			'#FF0000',
		],
	};

	public data: Observable<any> =
		combineLatest([
			this.authService.currentContract,
			this.scope.valueChanges.pipe(startWith(this.scope.value)),
		]).pipe(
			switchMap(([contract, scope]) => this.http.get<[string, string, number, number, number, number][]>( '/api/jobs/chart-data', {
				params: {
					'contractId': contract.id!,
					'scope': scope!,
				},
			})),
			map(data => data.map(([k, name, a, b, c, z]) => ({
				'name': `${k} • ${(z)} jobs\n${name??''}`.trim(),
				'series': [
					{
						'name': 'Pass',
						'value': b,
					},
					{
						'name': 'In Progress',
						'value': c,
					},
					{
						'name': 'Fail',
						'value': a,
					},
				],
			}))),
		);

	public constructor(
		private authService: AuthenticationService,
		private http: HttpClient,
	) {

	}

	public get contract() {
		return this.authService.currentContract.getValue();
	}

  public get contractUsers(){
    return this.authService.contractUsers;
  }

  ScaleType = ScaleType;

	public ngOnInit() {
	}

	public renderTooltip(model: any) {
		const data = this.chart.results.find(({name}: { name: string }) => name === model.series);
		const total = data.series.reduce((a: any, v: any) => a + v.value, 0);
		const percentage = (model.value / total) * 100;
		return {
			label: model.series + ' • ' + model.name,
			percentage: `${percentage.toFixed(0)}%`,
			total: model.value
		};
	}

  protected showUserAccessModal = false;
  viewUsers() {
    this.showUserAccessModal = true;
  }
  hideUsers() {
    this.showUserAccessModal=false;
  }
}

