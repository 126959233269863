<svg:g #ticksel xmlns:svg="http://www.w3.org/1999/XSL/Transform">
    <svg:g *ngFor="let tick of ticks" class="tick" [attr.transform]="tickTransform(tick)">
        <title>{{ tickFormat(tick) }}</title>
        <svg:text
            stroke-width="0.01"
            [attr.text-anchor]="'middle'"
            [style.font-size]="'12px'"
        >
            <tspan *ngFor="let line of tickFormat(tick).split('\n'); let i = index;" x="0" [attr.dy]="(1.2*i)+'em'">{{ line }}</tspan>
        </svg:text>
    </svg:g>
</svg:g>
<svg:g *ngFor="let tick of ticks" [attr.transform]="tickTransform(tick)" xmlns:svg="http://www.w3.org/1999/XSL/Transform">
    <svg:g *ngIf="true" [attr.transform]="gridLineTransform()">
        <svg:line class="gridline-path gridline-path-vertical" [attr.y1]="-gridLineHeight" y2="0" />
    </svg:g>
</svg:g>