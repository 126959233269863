import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../authentication.service';
import {User} from '../../interfaces/user';
import {CrmEntity} from '../crm/entity';
import {ReportService, ReportType} from './services/report.service';
import {Appointment} from "../../../richardirvin-entities/type-aliases";

@Component({
	selector: 'app-new-report-dialog',
	templateUrl: './new-report-dialog.component.html',
	styleUrls: ['./new-report-dialog.component.scss'],
})
export class NewReportDialogComponent implements OnInit {
	@ViewChild('newReportDialog') dialog!: ElementRef;
	@ViewChild('documents') documentsElm!: ElementRef;

	@Input() public visit!: Appointment;

	@Output() opened: EventEmitter<void> = new EventEmitter<void>();
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();
	@Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();

	newReportForm!: FormGroup;


	public docLength = 0;
	public docs: File[] = [];
	public visitId!: string;

	public $types: Observable<ReportType[]> = Observable.create();

	public get job() {
		return this.visit.formattedValues.find(e => e.key === 'regardingobjectid')!.value;
	}

	public get site() {
		return this.visit.formattedValues.find(e => e.key === 'ri_customersiteid')!.value;
	}

	constructor(
		private auth: AuthenticationService,
		private builder: FormBuilder,
		private reportService: ReportService,
	) {
		this.initForm();
		this.auth.profileUpdated.subscribe(async () => {
			await this.initForm();
		});
	}

	protected get engineer(): User {
		return this.auth.currentUser.getValue();
	}

	protected get times(): string[] {
		const times: Moment[] = [];
		const start = moment().startOf('day');
		const end = moment().endOf('day');

		while (times.length == 0 || times[times.length - 1] <= end.startOf('hour')) {
			if (times.length == 0) {
				times.push(start.clone());
			} else {
				const nextTime = times[times.length - 1].clone().add(30, 'minutes');
				times.push(nextTime);
			}
		}

		return times.map(time => time.format('HH:mm'));
	}

	public closeDialog(): void {
		this.dialog.nativeElement.close();
		this.newReportForm.reset();
		this.closed.emit(null!);
	}

	public openDialog(): void {
		this.dialog.nativeElement.showModal();
		this.opened.emit(null!);
	}

	protected get currentTime(): string {
		return moment().startOf('hour').format('HH:mm');
	}

	async ngOnInit() {
		await this.initForm();
	}

	public onDocumentChange(documents: HTMLInputElement) {
		const files = Array.from(documents.files!);

		this.newReportForm.value.documents = files;
		this.docLength = files.length;
		this.docs = files;//.map(f => f.name);
		// input.value = files.map(f => f.name).join(', \n');

	}

	public async submit() {
		if (this.newReportForm.dirty && this.newReportForm.valid) {
			try {
				this.saving.emit(true);
				this.dialog.nativeElement.close();

				await this.reportService.create(this.visit.id, this.newReportForm.value, this.docs);
				this.newReportForm.reset();

			} catch (ex) {
				this.openDialog();
				console.error(ex);
			} finally {
				this.saving.emit(false);
			}
		}
	}

	private async initForm() {
		this.newReportForm = this.builder.group({
			date: ['', Validators.required],
			time: [this.currentTime, Validators.required],
			type: ['', Validators.required],
			documents: [null],
		});

		try {
			this.$types = this.reportService.getTypes();
		} catch (e) {

		}

	}


}
