<ri-loader></ri-loader>

<app-xml-list-layout
        [(listView)]="selectedView"
        [entityList]="entities"
        (listViewChange)="handleViewChange()"
        showCheckbox="true">
</app-xml-list-layout>

<app-page-footer>
  <app-powered-by/>
  <app-pagination [currentPage]="currentPage" [maxRecords]="maxRecords" [records]="entities"></app-pagination>
</app-page-footer>

