import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmEntity, Entities} from '../../../_shared/crm/entity';
import {AuthenticationService} from '../../../authentication.service';
import {APIService} from '../../jobs/services/jobs.service';
import {firstValueFrom} from "rxjs";

@Injectable()
export class SiteService extends APIService {
	protected _api = '/api/sites';

	public constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
	) {
		super();
	}

	public getSites(params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/`, params);
	}

	public getSite(id: string): Promise<CrmEntity> {
		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<CrmEntity>(`${this._api}/${id}`, {headers}));
	}

	public getSitePaperwork(id: string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/${id}/paperwork`, params);
	}

	public getSiteVisits(id: string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/${id}/visits`, params);
	}

	public getSiteAssets(id: string, params: URLSearchParams): Promise<Entities> {
		return this.retrieveEntities(`/${id}/assets`, params);
	}

}
