
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../authentication.service';
import {SavedQuery} from '../../fetch-xml/saved-query';
import {Contract} from '../../interfaces/contract';
import {ListView} from '../../interfaces/list-view';
import {ListViewTypes} from '../list-view-types.enum';
import {firstValueFrom} from "rxjs";

interface GetViewsResponse {
	name: string;
	savedQueryId: string;
	fetchXml: string;
	layoutXml: string;
}

@Injectable()
export class ListViewService {

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
	) {
	}

	public getViews(view_type: ListViewTypes): Promise<ListView[]> {
		const params = new URLSearchParams();
		params.set('type', view_type.toString());
		const currentContract: Contract = this.auth.currentContract.getValue();
		params.set('contractId', currentContract.id);

		const headers = new HttpHeaders({
			Authorization: this.auth.getAuthorization()
		});

		return firstValueFrom(this.http.get<GetViewsResponse[]>(`/api/list-views?${params}`, {headers}).pipe(
			map<GetViewsResponse[], ListView[]>((data) => {
				return data.map((res) => ({
					name: res.name || 'DEFAULT | NO NAME SET',
					savedQueryId: res.savedQueryId,
					layoutXml: res.layoutXml,
					fetchXml: new SavedQuery(res.fetchXml),
				}));
			})));

	}

}
