import { Component, OnInit } from '@angular/core';
import {XAxisComponent} from '@swimlane/ngx-charts';

@Component({
  selector: 'g[app-charts-x-axis]',
  templateUrl: './charts-x-axis.component.html',
  styleUrls: ['./charts-x-axis.component.scss']
})
export class ChartsXAxisComponent extends  XAxisComponent {


}
