import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IsLoggedIn} from './_guards/auth.guard';
import {ContractService} from './contract.service';
import {LoginComponent} from './login/login.component';
import {PPMGuardGuard} from './ppm/ppmguard.guard';
import {AssetDetailsComponent} from './protected/assets/asset-details/asset-details.component';
import {AssetNavWrapperComponent} from './protected/assets/asset-nav-wrapper/asset-nav-wrapper.component';
import {AssetsListComponent} from './protected/assets/assets-list/assets-list.component';
import {HomeComponent} from './protected/home/home.component';
import {JobDetailsComponent} from './protected/jobs/job-details/job-details.component';
import {JobNavWrapperComponent} from './protected/jobs/job-nav-wrapper/job-nav-wrapper.component';
import {JobNoteComponent} from './protected/jobs/job-note/job-note.component';
import {JobNotesComponent} from './protected/jobs/job-notes/job-notes.component';
import {JobPaperworkComponent} from './protected/jobs/job-paperwork/job-paperwork.component';
import {JobsListComponent} from './protected/jobs/jobs-list/jobs-list.component';
import {NavbarWrapperComponent} from './protected/navbar-wrapper/navbar-wrapper.component';
import {PaperworksListComponent} from './protected/paperworks/paperworks-list/paperworks-list.component';
import {SiteAssetsComponent} from './protected/sites/site-assets/site-assets.component';
import {SiteDetailsComponent} from './protected/sites/site-details/site-details.component';
import {SiteNavWrapperComponent} from './protected/sites/site-nav-wrapper/site-nav-wrapper.component';
import {SitePaperworkComponent} from './protected/sites/site-paperwork/site-paperwork.component';
import {SiteVisitsComponent} from './protected/sites/site-visits/site-visits.component';
import {SitesListComponent} from './protected/sites/sites-list/sites-list.component';
import {VisitDetailsComponent} from './protected/visits/visit-details/visit-details.component';
import {VisitJobComponent} from './protected/visits/visit-job/visit-job.component';
import {VisitPaperworkComponent} from './protected/visits/visit-paperwork/visit-paperwork.component';
import {VisitSiteComponent} from './protected/visits/visit-site/visit-site.component';
import {VisitsListComponent} from './protected/visits/visits-list/visits-list.component';
import {JobVisitsComponent} from "./protected/jobs/job-visits/job-visits.component";
import {VisitNavWrapperComponent} from "./protected/visits/visit-nav-wrapper/visit-nav-wrapper.component";

const routes: Routes = [
	{path: 'login', component: LoginComponent},
	{
		path: '', component: NavbarWrapperComponent, canActivate: [IsLoggedIn],
		resolve: {
			contracts: ContractService,
		},
		children: [
			{path: '', component: HomeComponent},
			{path: 'home', component: HomeComponent},

			{path: 'jobs', component: JobsListComponent},
			{
				path: 'jobs/:id', component: JobNavWrapperComponent,
				children: [
					{path: '', redirectTo: 'details', pathMatch: 'full'},
					{path: 'details', component: JobDetailsComponent},
					{path: 'visits', component: JobVisitsComponent},
					{path: 'visits/:id', redirectTo: '/visits/:id/details', pathMatch: 'full'},
					{path: 'paperwork', component: JobPaperworkComponent},
					{path: 'notes', component: JobNotesComponent},
					{path: 'notes/:id', component: JobNoteComponent},
				],
			},

			{path: 'visits', component: VisitsListComponent},
			{
				path:     'visits/:id', component: VisitNavWrapperComponent,
				children: [
					{path: '', redirectTo: 'details', pathMatch: 'full'},
					{path: 'details', component: VisitDetailsComponent},
					{path: 'job', component: VisitJobComponent},
					{path: 'site', component: VisitSiteComponent},
					{path: 'paperwork', component: VisitPaperworkComponent},
				],
			},

			{path: 'sites', component: SitesListComponent},
			{
				path:     'sites/:id', component: SiteNavWrapperComponent,
				children: [
					{path: '', redirectTo: 'details', pathMatch: 'full'},
					{path: 'details', component: SiteDetailsComponent},
					{path: 'visits', component: SiteVisitsComponent},
					{path: 'visits/:visit_id', component: VisitDetailsComponent},
					{path: 'assets', component: SiteAssetsComponent},
					{path: 'assets/:assets_id', redirectTo: 'details', pathMatch: 'full'},
					{path: 'assets/:assets_id/details', component: AssetDetailsComponent},
					{path: 'paperwork', component: SitePaperworkComponent},
				],
			},

			{path: 'assets', component: AssetsListComponent},
			{
				path:     'assets/:id', component: AssetNavWrapperComponent,
				children: [
					{path: '', redirectTo: 'details', pathMatch: 'full'},
					{path: 'details', component: AssetDetailsComponent},
				],
			},
			{path: 'paperwork', component: PaperworksListComponent},
			{ path: 'ppm',  canActivate: [PPMGuardGuard], loadChildren: () => import('./ppm/ppm.module').then(m => m.PpmModule) },
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always'})],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
