<div class="wrapper">
<section>
    <h1 class="title">welcome&nbsp;</h1>
    <h2 class="title" *ngIf="user$ | async as user; ">{{ user.name }}</h2>

    <ng-container *ngIf="contract.showKpiDashboard">
        <div style="display: flex">
        <div class="field" style="width: fit-content; margin: 0 2em 1em 0; flex:none;">
            <div class="control">
                <div class="select is-small">
                    <select [formControl]="scope">
                        <option value="current">Current Month</option>
                        <option value="previous">Previous Month</option>
                        <option value="year">Year to Date</option>
                        <option value="previous-year">Previous Year</option>
                    </select>
                </div>
            </div>
        </div>
            <h3>Reactive Repair, Attendance – KPI</h3>
        </div>

        <app-chart
            #chart
            [animations]="false"
            [barPadding]="64"
            [legendTitle]="''"
            [legend]="false"
            [results]="data|async"
            [schemeType]="ScaleType.Ordinal"
            [scheme]="colorScheme"
            [view]="[600, 400]"
            [xAxis]="true"
            [yAxis]="true"
        >
            <ng-template #tooltipTemplate let-model="model">
                <ng-container #tooltip=var [var]="renderTooltip(model)">
                <span class="ng-star-inserted">
                    <span class="tooltip-label">{{ tooltip.value.label }}</span>
                    <span class="tooltip-val">{{ tooltip.value.total }} • {{ tooltip.value.percentage }}</span>
                </span>
                </ng-container>
            </ng-template>


        </app-chart>
    </ng-container>
</section>

<section>

    <div class="account-information">
        <p>
            Our customer portal provides a 360-degree view of your managed sites and assets.
            Our cutting-edge technology allows secure access to all your data and compliance documents, all in line with helping you maintain safe, healthy, and productive environments.
        </p>
        <br>
        <p>
            We love feedback.
            Please let us know if you cannot find what you are looking for or have a great idea to enhance our portal even more.
            Our technology team are ready to help.
        </p>
        <br>
        <a class="mail-button" href="mailto:FMSERV@richard-irvin.com">
            FMSERV@richard-irvin.com
            <span class="ico"></span>
        </a>
    </div>

    <div class="my-details">

        <h1>Contract Information</h1>

        <div class="account-details" *ngIf="contract">

            <dl>
                <dt>Account name</dt>
                <dd>{{ contract.account.name }}&nbsp;</dd>

                <dt>Account number</dt>
                <dd>{{ contract.account.number }}&nbsp;</dd>
            </dl>
            <dl>
                <dt>Contract Start Date</dt>
                <dd>{{ contract.activeOn | date:'dd/MM/yyyy' }}&nbsp;</dd>

                <dt>Contract End Date</dt>
                <dd>{{ contract.expiresOn | date:'dd/MM/yyyy' }}&nbsp;</dd>
            </dl>

          <dl>
            <dt>Contract Access</dt>
  <!--          <ng-container *ngIf="contractUsers | async as users">
              <ng-container *ngIf="users.length < 5">
                <ng-container *ngFor="let user of users">
                  <dd>{{ user.name }}</dd>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="users.length >= 5">
                <dd><a (click)="viewUsers()" class="mail-button">View Users</a></dd>
              </ng-container>
            </ng-container>-->

            <dd><a (click)="viewUsers()" class="mail-button">View Users</a></dd>
          </dl>


        </div>
    </div>
</section>
</div>
<app-page-footer>
  <app-powered-by />
</app-page-footer>

<ng-container *ngIf="showUserAccessModal">
<div style="position: absolute; left:0; right:0; top:0; bottom:0;background: rgba(0,0,0,0.25); z-index: 999;">
  <div class="modal-card" style="margin-top: 4rem">
    <header class="modal-card-head">
      <p class="modal-card-title">Users</p>
      <button (click)="hideUsers()" aria-label="close" class="delete"></button>
    </header>

    <div class="modal-card-body">
      <ng-container *ngIf="contractUsers | async as users">
        <ng-container *ngFor="let user of users">
          <div class="field">{{ user.name }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
</ng-container>
