<ri-loader></ri-loader>

<header>
    <div id="logo">
        <img src="/assets/logo.png" alt="" width="360" height="140">
    </div>
    <nav class="nav-wrapper">
    </nav>
</header>

<div class="page">
    <div class="home-page-wrapper">
        <section class="test">
            <h1>
                Please <br> login <br> to <br> access
            </h1>
            <h2>your portal</h2>
        </section>

        <section class="test">
            <form [formGroup]="loginForm" (submit)="submitLogin()" (keyup.enter)="submitLogin()">
                <label>
                    <input formControlName="username" id="username" placeholder="username">
                </label>

                <label>
                    <input formControlName="password" id="password" type="password" placeholder="password">
                </label>

                <!--<label class="secondary">Remember me-->
                <!--<input type="checkbox" name="remember" value="checked" checked>-->
                <!--</label>-->

                <a class="submit-btn" (click)="submitLogin()">
                    Login
                </a>
            </form>
        </section>
    </div>
</div>

<footer>
    <h6 class="powered-by">powered by <span style="font-weight: bold;">FMServ</span></h6>
</footer>