import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ToolbarButtonComponent} from './toolbar-button/toolbar-button.component';
import {ToolbarCsvDownloadComponent} from './toolbar-csv-download/toolbar-csv-download.component';
import {ToolbarFilterComponent} from './toolbar-filter/toolbar-filter.component';
import {ToolbarIconComponent} from './toolbar-icon/toolbar-icon.component';
import {ToolbarLinkComponent} from './toolbar-link/toolbar-link.component';
import {ToolbarSpacerComponent} from './toolbar-spacer/toolbar-spacer.component';
import {ToolbarTitleComponent} from './toolbar-title/toolbar-title.component';
import { ToolbarSelectComponent } from './toolbar-select/toolbar-select.component';
import { OptionDirective } from './toolbar-select/option.directive';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
    exports: [
        ToolbarFilterComponent,
        ToolbarSpacerComponent,
        ToolbarButtonComponent,
        ToolbarTitleComponent,
        ToolbarLinkComponent,
        ToolbarIconComponent,
        ToolbarCsvDownloadComponent,
        ToolbarSelectComponent,
        OptionDirective,
        ToolbarSearchComponent,
    ],
	declarations: [
		ToolbarFilterComponent,
		ToolbarSpacerComponent,
		ToolbarButtonComponent,
		ToolbarTitleComponent,
		ToolbarLinkComponent,
		ToolbarIconComponent,
		ToolbarCsvDownloadComponent,
		ToolbarSelectComponent,
		OptionDirective,
  ToolbarSearchComponent,
	],
})
export class ToolbarModule {
}
