import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CSVProgressEvent} from './c-s-v-progress.event';
import {Download} from './download';

// Angular broke this, no idea how...
// todo: replace this with actual types
declare type EventSource = any & EventTarget;
declare const EventSource: EventSource;

@Injectable()
export class CsvExportService {
	public readonly downloads: Download[] = [];
	public $downloadProgress: Observable<CSVProgressEvent>;
	private eventSource: EventSource;

	constructor(
		public http: HttpClient,
	) {
		this.eventSource = new EventSource('/api/events', {withCredentials: true});

		 fromEvent<MessageEvent>(this.eventSource, 'csvError').pipe(
			 map(e => JSON.parse(e.data) as CSVProgressEvent),
			 )	.subscribe(evt => {
			 if (confirm('Server error while preparing workbook: ' + evt.filename + ', download error file?')) {
				 const blob = new File([evt.id+'\n'+evt.exception], 'error.log', {type:'text/plain'});
				 open(URL.createObjectURL(blob));
			 }
		 });

		this.$downloadProgress = fromEvent<MessageEvent>(this.eventSource, 'csvProgress').pipe(
			map(e => JSON.parse(e.data) as CSVProgressEvent),
			tap(event => {
				let download = this.downloads.find(download => download.id === event.id);

				if (!download) {
					// download = event;
					// this.downloads.push(download);
          return;
				}
				else {
					Object.assign(download, event);
				}

				if (event.progress === 1) {
					const a = document.createElement('a');
					a.href = `/api/csv/${download.id}`;
					a.download = download.filename;
					a.target = '_blank';
					a.click();
				}
			})
		);
	}


	/**
	 * Listens for all CSV progress events
	 */
	// public csvProgress(): Observable<CSVProgressEvent> {
	// 	return new Observable((subscriber) => {
	// 		function onMessage(evt: MessageEvent) {
	// 			try {
	// 				const data = JSON.parse(evt.data);
	// 				subscriber.next(data);
	// 			} catch (ex) {
	// 				subscriber.error(ex);
	// 			}
	// 		}
	//
	// 		function onError(evt: ErrorEvent) {
	// 			subscriber.error(evt.error);
	// 		}
	//
	// 		this.eventSource.addEventListener('csvProgress', onMessage);
	//
	// 		return () => {
	// 			this.eventSource.removeEventListener('csvProgress', onMessage);
	// 		};
	// 	});
	// }

	public addDownload(download: Download) {
		if (download.id) {
			const existingDownload = this.downloads.find(dl => dl.id === download.id);
			if (existingDownload) {
				Object.assign(existingDownload, download);
			} else {
				this.downloads.push(download);
			}
		}
	}

	public removeDownload(download: Download) {
		const index = this.downloads.indexOf(download);
		this.downloads.splice(index, 1);
	}

	public async cancelDownload(download: Download) {
		try {
			await this.http.get(`/api/csv/${download.id}/cancel`).toPromise();
		}
		catch (ex) {
			console.log(ex);
		}
		finally {
			this.removeDownload(download);
		}
	}
}
