import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../../ri-loader-component/loader.module';
import {SharedModule} from '../../_shared/shared.module';
import {ToolbarModule} from '../../toolbar/toolbar.module';
import {PaperworksListComponent} from './paperworks-list/paperworks-list.component';
import {PaperworkService} from './services/paperwork.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		RouterModule,
		LoaderModule,
		ToolbarModule,
	],
	declarations: [
		PaperworksListComponent,
	],
	providers: [
		PaperworkService,
	],
})
export class PaperworksModule {
}
