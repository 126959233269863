import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Entities} from '../crm/entity';

@Component({
	selector:    'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls:   ['./pagination.component.scss'],
})
export class PaginationComponent {
	@Input() records: Entities = Object.assign([], {
		totalRecordCount:              -1,
		totalRecordCountLimitExceeded: false,
		moreRecords:                   true,
	});

	@Input() currentPage = 1;
	@Input() maxRecords  = 25;

	@Output() currentPageChange = new EventEmitter<number>();
	@Output() maxRecordsChange  = new EventEmitter<number>();

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
	) {
	}

	public get pages() {
		if (this.records.totalRecordCount === -1) return Infinity;
		return Math.ceil(this.records.totalRecordCount / this.maxRecords);
	}

	protected get path(): string {
		return this.route.snapshot.url.map(s => s.path).join('/');
	}
}
