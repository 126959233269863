import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrmEntity} from '../../../_shared/crm/entity';
import {ListViewTypes} from '../../../_shared/list-view-types.enum';
import {ListViewService} from '../../../_shared/services/list-view.service';
import {AuthenticationService} from '../../../authentication.service';
import {EntityList} from '../../_controllers/entity-list';
import {PaperworkReport} from '../../paperworks/paperworks-list/paperworks-list.component';
import {JobsService} from '../services/jobs.service';


@Component({
	selector:    'app-job-paperwork',
	templateUrl: './job-paperwork.component.html',
	styleUrls:   ['./job-paperwork.component.scss'],
})
export class JobPaperworkComponent extends EntityList {
	public listViewType: ListViewTypes = ListViewTypes.JobPaperwork;

	public constructor(
		protected jobsSvc: JobsService,
		protected listViewSvc: ListViewService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected override toast: MatSnackBar,
		protected override auth: AuthenticationService,
	) {
		super();
	}

	public override async ngOnInit(): Promise<void> {
		await super.ngOnInit();

		this.listLayout.addColumn('report', 'Report', 100,
			PaperworkReport.getValue,
			PaperworkReport.onClick,
		);
	}

	protected getEntities(params: URLSearchParams): Promise<CrmEntity[]> {
		return this.jobsSvc.getJobPaperwork(this.id, params);
	}
}
