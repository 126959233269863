import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Component, EventEmitter, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthenticationService} from '../../../authentication.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
	public response!: string;
	@Output()
	public submitted = new EventEmitter<void>();

	public constructor(
		private authSvc: AuthenticationService,
		private http: HttpClient,
	) {
	}

	public async submit(frm: NgForm) {
		try {
			this.response = null!;
			const headers = new HttpHeaders().append('Authorization', this.authSvc.getAuthorization());

			await this.http.post<void>('/api/change-password', frm.value, {headers}).toPromise();
			frm.resetForm();
			this.response = 'Password updated successfully';
			this.submitted.emit();
		} catch (ex) {
			if (ex instanceof HttpErrorResponse) {
				this.response = 'There was an error updating your password.';
			} else {
				throw ex;
			}
		}
	}

}

