import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Filter} from '../../_shared/page-toolbar/filter';
import {ListView} from '../../interfaces/list-view';

@Component({
	selector:    'app-toolbar-filter',
	templateUrl: './toolbar-filter.component.html',
	styleUrls:   ['./toolbar-filter.component.scss'],
})
export class ToolbarFilterComponent implements OnInit, OnChanges {
	@Input() public filter!: Filter;
	@Input() public selectedView!: ListView;
	@Output() public selectedViewChanged = new EventEmitter<ListView>();
	public selectOpen: boolean = false;

	public ngOnInit(): void {
	}

	public ngOnChanges(): void {
		this.selectedViewChanged.emit(this.selectedView);
	}

	public handleOptionClick(option: ListView): void {
		this.selectedView = option;
		this.selectedViewChanged.emit(this.selectedView);
	}
}
