import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-toolbar-button',
	templateUrl: './toolbar-button.component.html',
	styleUrls: ['./toolbar-button.component.scss'],
})
export class ToolbarButtonComponent implements OnInit {
	@Output() public click = new EventEmitter();

	@Input() icon!: string;
	@Input() disabled!: boolean;

	constructor() {
	}

	ngOnInit() {
	}

	public handleClick(event: Event) {
		this.click.emit(event);
	}

}
