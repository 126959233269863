<ng-content></ng-content>
<!--
<div class="page-toolbar" hidden>
    <h3 *ngIf="title">{{ title }}</h3>
    &lt;!&ndash;<ng-content></ng-content>&ndash;&gt;

    <label *ngIf="filter">
        Filter by {{ filter.name }}
        <select [(ngModel)]="selectedFilter" (change)="filterChanged($event)">
            <option *ngFor="let option of filter.options" [ngValue]="option">{{ option.name }}</option>
        </select>
    </label>

    <div class="spacer"></div>

    <button *ngIf="newButton" class="toolbar-btn" (click)="addNewButtonClicked()">
        Add New {{newButton}}
    </button>

    <form ngNoForm *ngIf="csvDownload && csvDownload.url && csvDownload.fetchXml" action="{{csvDownload.url}}"
          target="_blank" method="post">
        <input type="hidden" name="fetchXml" [value]="csvDownload.fetchXml">
        <button class="toolbar-btn">
            Download as CSV
        </button>
    </form>

    <button *ngIf="refresh" (click)="refreshButtonClicked()">
        &lt;!&ndash; TODO: Add refresh icon &ndash;&gt;
        ref
    </button>

</div>-->
