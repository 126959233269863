<div class="ri-modal">
    <button class="close" (click)="closeDialog()"></button>
    <div class="dialog-inner">
        <h1>Edit visit status</h1>
        <form [formGroup]="visitStatusForm" class="form" (submit)="updateVisitStatus()">
            <div class="form-main">
                <div class="inputs">
                    <label>Requested Priority
                        <select formControlName="status">
                            <option *ngFor="let status of visitStatuses" [value]="status">
                                {{visitStatusLabels[status]}}
                            </option>
                        </select>
                    </label>

                    <label class="expanded">Description
                        <textarea rows="5" formControlName="details"></textarea>
                    </label>
                </div>
            </div>
            <div class="form-sidebar">
                <div class="inputs">
                </div>
                <div class="controls">
                    <button class="success" [class.disabled]="!visitStatusForm.valid">save</button>
                </div>
            </div>
        </form>
    </div>
</div>
