///<reference types="@types/webappsec-credential-management"/>
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, forkJoin as observableForkJoin, switchMap} from 'rxjs';
import {Contract} from './interfaces/contract';
import {User} from './interfaces/user';

export type AuthenticationState = 'unauthenticated' | 'authenticated' | 'expired' | 'pending';


export interface ContractUser {
  id:string;
  name:string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    readonly #httpClient = inject(HttpClient);

    readonly #currentContract = new BehaviorSubject<Contract>(null!);
    readonly #token = new BehaviorSubject<string|null>(null);

    get token() {
        return this.#token.value;
    }


    async silentAuth() {
        try {
            const credentials = await navigator.credentials.get({password: true});
            if (credentials instanceof PasswordCredential) {
                // await this.login(credentials.id, credentials.password!);
                // this.state.next('authenticated');
                this.state.next('unauthenticated');
            }
            else {
                this.state.next('unauthenticated');
            }
        } catch {
            console.error("Silent auth failed");
            this.state.next('unauthenticated');
        }
    }


    public readonly state = new BehaviorSubject<AuthenticationState>('pending');
    public readonly currentUser = new BehaviorSubject<User>(null!);

    public readonly profileUpdated = observableForkJoin([this.currentUser, this.currentContract]);

    public readonly contractUsers = this.#currentContract.pipe(
      switchMap(contract => this.#httpClient.get<ContractUser[]>(`api/contracts/${contract.id}/users`))
    );

    public get currentContract() {
        const subject = this.#currentContract;
        return Object.assign(subject.asObservable(), {
            get value() {
                return subject.value;
            },
            getValue: () => {
                return subject.getValue();
            },
        });
    }

    public changeCurrentContract(contract: Contract) {
        this.#currentContract.next(contract);
        localStorage.setItem('selectedContract', contract.id);
    }



    public async login(username: string, password: string): Promise<User> {
        try {
            // todo check response
            const user = await firstValueFrom(this.#httpClient.post<User>(`/api/login`, {username, password}));
            try {
                await navigator.credentials.store(new PasswordCredential({
                    id: username,
                    password: password,
                    name: user.name,

                }));
            } catch (ex) {
                console.error(ex);
            }

            this.currentUser.next(user);
            this.state.next('authenticated');

            return user!;
        } catch (ex) {
            if (ex instanceof HttpErrorResponse) {
                throw ex.error!;
            } else {
                throw ex;
            }
        }
    }

    /**
     * @deprecated
     */
    public getAuthorization(): string {
        return `Bearer ${this.token}`;
    }

    public async logout(): Promise<void> {
        localStorage.clear();
        this.state.next('unauthenticated');
    }
}
