<div class="table-viewport">
    <table style="height: auto; position: sticky; top: 0;">
        <thead>
        <tr>
            <th *ngIf="showCheckbox"
                width="{{ (40) }}"
                (click)="selectAll()"
            >
                <input type="checkbox"
                       style="appearance: auto; position: unset; "
                       [checked]="isPageSelected()"
                       [indeterminate]="!isPageSelected() && selectionModel.hasValue()"
                >
            </th>
            <th *ngFor="let column of columns"
                width="{{ (column.width+60) }}"
                scope="col"
                [hidden]="column.hidden"
                [title]="column.label"
                [attr.data-name]="column.name"
                [attr.data-type]="FilterTypes[column.type]"
            >
                <div style="display: inline-block; overflow: hidden; text-overflow: ellipsis;"
                     [ngStyle]="{width: column.width+'px'}">{{ column.label }}
                </div>

                <div *ngIf="column.custom !== true" style="float: right">
                    <fa-icon style="cursor: pointer;"
                             *ngIf="column.type !== FilterTypes.Lookup"
                             [class.filter-by]="this.columnFilter && this.columnFilter.column.name === column.name"
                             (click)="filterBy(column)"
                             [icon]="'filter'"
                    ></fa-icon>&nbsp;

                    <fa-icon style="cursor: pointer;"
                             (click)="sortBy(column)"
                             *ngIf="column.type !== FilterTypes.PartyList"
                             [icon]="$any(columnSort && columnSort.column === column.name ? 'sort-'+(columnSort.direction==='asc'?'up':'down') : 'sort')"
                    ></fa-icon>
                </div>
            </th>
        </tr>
        </thead>
    </table>
    <table>
        <thead >
        <tr style="visibility: collapse;">
            <th *ngIf="showCheckbox"
                width="{{ 40 }}">&nbsp;</th>
            <th *ngFor="let column of columns"
                [hidden]="column.hidden"
                width="{{ (column.width+60) }}"
            >&nbsp;</th>
        </tr>
        </thead>

        <tfoot>
        <tr *ngIf="entityList.length === 0">
            <td [colSpan]="visibleColumns.length || 1" style="text-align: center; color: white;">
                <div style="max-width: 100vw;">No records found</div>
            </td>
        </tr>
        </tfoot>

        <tbody>
        <tr *ngFor="let entity of entityList"
            (click)="rowClick(entity)">
            <td *ngIf="showCheckbox" (click)="toggle(entity.id)">
                <label class="checkbox">
                    <input type="checkbox"
                           style="appearance: auto; position: unset; "
                           [checked]="selectionModel.isSelected(entity.id)"
                    >
                </label>
            </td>

            <ng-container *ngFor="let column of columns">
                <td *ngIf="column.custom" (click)="cellClick($event, entity, column)"
                    [innerHTML]="getEntityValue(entity, column)"
                    [title]="getEntityValue(entity, column)"
                ></td>

                <td *ngIf="!column.custom" [hidden]="column.hidden" [attr.data-name]="column.name"
                    [title]="getEntityValue(entity, column)"
                [innerHTML]="getEntityValue(entity, column)">
                </td>
            </ng-container>
        </tr>
        </tbody>
    </table>
</div>

<app-filter-dialog
    [(filterBy)]="columnFilter"
    (filterByChange)="updateFilter($event)"
></app-filter-dialog>
