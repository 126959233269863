import { Component, OnInit } from '@angular/core';
import {XAxisTicksComponent} from '@swimlane/ngx-charts';

@Component({
  selector: 'g[app-charts-x-axis-ticks]',
  templateUrl: './charts-x-axis-ticks.component.html',
  styleUrls: ['./charts-x-axis-ticks.component.scss']
})
export class ChartsXAxisTicksComponent extends XAxisTicksComponent{

}
