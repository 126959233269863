<app-page-toolbar>
    <app-toolbar-title>Visit > {{ visit?.subject }}</app-toolbar-title>
    <app-toolbar-spacer></app-toolbar-spacer>

    <app-toolbar-button *ngIf="auth.currentContract.getValue().isSubContractor" (click)="openNewReportDialog()"
                        icon="/assets/icons/chevron_down_white.svg">Add New Report
    </app-toolbar-button>

    <ng-container *ngIf="listLayout">
        <app-toolbar-csv-download
                (click)="downloadBatch()"
                [disabled]="listLayout.selectionModel.isEmpty()">
            Download Selected
        </app-toolbar-csv-download>
    </ng-container>

    <app-toolbar-button (click)="backButtonClick()" icon="/assets/icons/chevron_left_white.svg">
        Back to visits
    </app-toolbar-button>

</app-page-toolbar>

<nav class="page-nav">
    <div class="page-nav-item" routerLink="details" routerLinkActive="selected">details</div>
    <div class="page-nav-item" routerLink="paperwork" routerLinkActive="selected">documentation</div>
</nav>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<app-new-report-dialog [visit]="visit"></app-new-report-dialog>
