export type CaughtErrorStatus = 'error' | 'warning' | 'info';

export default class CaughtError extends Error {
	public readonly title: string;
	public readonly status: CaughtErrorStatus;
	public readonly caughtError: Error;

	public constructor(
		title: string,
		message: string,
		error: Error,
		status: CaughtErrorStatus = 'error',
	) {
		super(message);
		this.title = title;
		this.status = status;
		this.caughtError = error;
		Object.setPrototypeOf(this, CaughtError.prototype);
	}

	static Error(title: string, message: string, error: unknown) {
		return new CaughtError(title, message, error as Error, 'error');
	}

	static Warning(title: string, message: string, error: unknown) {
		return new CaughtError(title, message, error as Error, 'warning');
	}

	static Info(title: string, message: string, error: unknown) {
		return new CaughtError(title, message, error as Error, 'info');
	}
}
