import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthenticationService} from '../../authentication.service';
import {DirectivesModule} from '../directives/directives.module';
import {NewJobDialogComponent} from './new-job-dialog.component';
import {JobService} from './services/job.service';
import {SitesService} from './services/sites.service';
import {FileDirective} from "../../file.directive";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
	],
	providers: [JobService, SitesService],
	declarations: [NewJobDialogComponent,
    FileDirective,],
	exports: [NewJobDialogComponent],
})
export class NewJobDialogModule {
}
