import {ChangeDetectorRef, Component, inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {LoaderComponent} from '../../../../ri-loader-component/loader.component';
import {proxyCrmEntity} from '../../../_shared/crm/proxy-crm-entity';
import {NewNoteDialogComponent} from '../../../_shared/new-note-dialog/new-note-dialog.component';
import {JobsService} from '../services/jobs.service';
import {Job} from "../../../../richardirvin-entities/type-aliases";
import {firstValueFrom} from "rxjs";
import {EntityList} from "../../_controllers/entity-list";
import {XmlListLayoutComponent} from "../../../_shared/xml-list-layout/xml-list-layout.component";
import {HttpClient} from "@angular/common/http";


@Component({
	selector: 'app-job-nav-wrapper',
	templateUrl: './job-nav-wrapper.component.html',
	styleUrls: ['./job-nav-wrapper.component.scss'],
})
export class JobNavWrapperComponent implements OnInit {
	@ViewChild(NewNoteDialogComponent, { static: true }) newNoteDialog!: NewNoteDialogComponent;
	@ViewChild(LoaderComponent, { static: true }) protected loader!: LoaderComponent;

	id!: string;
	job!: Job;

	casenumber!: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private jobSvc: JobsService,
	) {
	}

	public ngOnInit(): void {
		this.route.paramMap.pipe(
			switchMap((params: ParamMap) => {
				this.id = params.get('id')!;
				return this.jobSvc.getJob(params.get('id')!);
			}),
		).subscribe((job) => {
			this.job = proxyCrmEntity<Job>(job);
			this.casenumber = job.attributes.find(a => a.key === 'ticketnumber')!.value as string;
		});
	}

	public openNewNoteDialog() {
		this.newNoteDialog.openDialog();
	}

	public handleNewNoteSaving(saving: boolean) {
		if (saving) {
			this.loader.show('Creating new note');
		} else {
			this.loader.dismiss();
		}
	}

	public backButtonClick() {
		this.router.navigate(['../'], {relativeTo: this.route});
	}
	listLayout?: XmlListLayoutComponent;

	ngZone = inject(NgZone);
	changeRef = inject(ChangeDetectorRef);
	http = inject(HttpClient);
	onActivate($event: any) {
		if ($event instanceof EntityList) {
			this.listLayout = $event.listLayout;
		} else {
			this.listLayout = undefined;
		}
		this.changeRef.detectChanges();
	}
	async 	downloadBatch() {
		if (this.listLayout === undefined) {
			return;
		}
		const ids = this.listLayout.selectionModel.selected;
		let formData = new FormData();

		ids.forEach(id => formData.append('ids', id));


		let handle: any;

		try {
			handle = await window.showSaveFilePicker({
				suggestedName: 'batch.zip',
				excludeAcceptAllOption: true,
				types: [
					{accept: {'application/zip': ['.zip']}, description: 'Zip Archive of PDF Files'},
					{accept: {'application/pdf': ['.pdf']}, description: 'Single PDF File'},
				]
			});
		}
		catch (e) {
			return;
		}

		if (handle == null) {
			return;
		}


		const blob = await firstValueFrom(this.http.post(`/api/batch-pdf/${handle.name}`, formData, {
			responseType: 'blob',
		}));

		const writable = await handle.createWritable({keepExistingData: false});

		await writable.write(blob);
		await writable.close();
	}
}
