import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '../pipes/pipes.module';
import {TabIsVisiblePipe} from './pipes/tab-is-visible.pipe';
import {XmlPageLayoutComponent} from './xml-page-layout.component';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
	],
	declarations: [XmlPageLayoutComponent, TabIsVisiblePipe],
	exports: [XmlPageLayoutComponent],
})
export class XmlPageLayoutModule {
}
