import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DirectivesModule} from '../directives/directives.module';
import {FilterDialogComponent} from './filter-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DirectivesModule,
		HttpClientModule,
	],
	declarations: [FilterDialogComponent],
	exports: [FilterDialogComponent],
})
export class FilterDialogModule {
}
